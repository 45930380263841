import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import { useGet } from "../../hooks/api-call";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const TruckFilters = ({ fetchTrucksData, isDrawerOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({
    ownerId: "",
    driverId: "",
    truckHypothecated: "",
  });

  const { responseData: owners } = useGet(`owners`, null, [isDrawerOpen]);
  const { responseData: drivers } = useGet(`drivers`, null, [isDrawerOpen]);

  const handleChange = ({ target: { name, value } }) => {
    setFilters((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSearch = (data) => {
    fetchTrucksData(0, data || filters);
  };

  const handleClearFilters = () => {
    const data = { ownerId: "", driverId: "", truckHypothecated: "" };
    setFilters(data);
    setTimeout(() => handleSearch(data), 100);
  };

  const handleOpenFilter = () => {
    setIsOpen((prev) => {
      const element = document.getElementById("truck-filter-section");
      if (prev) {
        element.style.height = "0px";
        setFilters({
          ownerId: "",
          driverId: "",
          truckHypothecated: "",
        });
      } else {
        setTimeout(() => {
          element.style.height = element.scrollHeight + "px";
        });
      }
      return !prev;
    });
  };

  return (
    <Box
      mt={5}
      bg={"#fff"}
      p={4}
      boxSizing="border-box"
      borderRadius={"5px"}
      boxShadow={"base"}
    >
      <Flex
        alignItems={"center"}
        style={{ userSelect: "none" }}
        mb={isOpen ? 2 : 0}
        onClick={handleOpenFilter}
        cursor={"pointer"}
      >
        {isOpen ? <BsChevronUp /> : <BsChevronDown />}
        <Text
          ml={2}
          fontWeight={"bold"}
        >
          Filter
        </Text>
      </Flex>
      <Box
        id="truck-filter-section"
        h={0}
        transition={"height 0.4s ease-out"}
        overflow={"hidden"}
        bg={"#eef2f5"}
        p={isOpen && 2}
        borderRadius={5}
      >
        <Flex gap={2} align="end">
          <FormControl>
            <FormLabel color="gray.600">Owners</FormLabel>
            <Select
              name="ownerId"
              onChange={handleChange}
              value={filters.ownerId}
              placeholder={" Select Owners"}
            >
              {owners &&
                owners.length > 0 &&
                owners.map((owner, index) => {
                  return (
                    <option value={owner.ownerId} key={index}>
                      {owner?.firstName}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          {/* Form Field 2 */}
          <FormControl>
            <FormLabel color="gray.600">Drivers</FormLabel>
            <Select
              name="driverId"
              onChange={handleChange}
              value={filters.driverId}
              placeholder={"Select Drivers"}
            >
              {drivers &&
                drivers.length > 0 &&
                drivers.map((driver, index) => {
                  return (
                    <option value={driver.driverId} key={index}>
                      {`${driver?.firstName || ""} ${driver?.lastName || ""}`}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          {/* Form Field 3 */}
          <FormControl>
            <FormLabel color="gray.600">Hypothecated</FormLabel>
            <Select
              name="truckHypothecated"
              onChange={handleChange}
              alue={filters.truckHypothecated}
              placeholder={"Choose option"}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>
        </Flex>
        <Flex justifyContent={"flex-end"} mt={3}>
          <Button
            variant={"solid"}
            bg={
              Object.values(filters).filter(Boolean).length === 0
                ? " "
                : "gray.200"
            }
            mr={3}
            w="100px"
            onClick={handleClearFilters}
            color={
              Object.values(filters).filter(Boolean).length === 0
                ? "gray.300"
                : ""
            }
          >
            Clear all
          </Button>
          <Button
            variant={"solid"}
            bg="blue.600"
            color="white"
            w="180px"
            onClick={() => handleSearch()}
            _hover={{
              backgroundColor: "#054180",
            }}
          >
            Search
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default TruckFilters;
