import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box, TextField, Typography, Grid2, MenuItem } from "@mui/material";
import { indianStates } from "../../../hooks/helper";

const AddressDetails = ({ control, errors }) => {

    return (
        <>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#333", p: 1, mb: 2, mt: 2, borderLeft: "4px solid #1976d2", bgcolor: "#f0f0f0", pl: 2 }}>
                Address Details
            </Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={4} sm={4}>
                    <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Address"
                                error={!!errors.address}
                                helperText={errors.address?.message}
                            />
                        )}
                    />
                </Grid2>
                <Grid2 size={4} sm={4}>
                    <Controller
                        name="landmark"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Landmark"
                                error={!!errors.landmark}
                                helperText={errors.landmark?.message}
                            />
                        )}
                    />
                </Grid2>
                <Grid2 size={4} sm={4}>
                    <Controller
                        name="locality"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Village/Town"
                                error={!!errors.locality}
                                helperText={errors.locality?.message}
                            />
                        )}
                    />
                </Grid2>
                <Grid2 size={4} sm={4}>
                    <Controller
                        name="tehsil"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Tehsil"
                                error={!!errors.tehsil}
                                helperText={errors.tehsil?.message}
                            />
                        )}
                    />
                </Grid2>
                <Grid2 size={4} sm={4}>
                    <Controller
                        name="district"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="District"
                                error={!!errors.district}
                                helperText={errors.district?.message}
                            />
                        )}
                    />
                </Grid2>
                <Grid2 size={4} sm={4}>
                    <Controller
                        name="state"
                        control={control}
                        rules={{ required: "State is required" }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                select
                                label="Select State"
                                {...field}
                                size="medium"
                                error={!!errors.state}
                                helperText={errors.state?.message}
                            >
                                {indianStates.map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid2>
                <Grid2 size={4} sm={4}>
                    <Controller
                        name="pincode"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label="Pincode"
                                type="number"
                                error={!!errors.pincode}
                                helperText={errors.pincode?.message}
                            />
                        )}
                    />
                </Grid2>
            </Grid2>
        </>
    );
};

export default AddressDetails;
