import { createTheme, ThemeProvider } from '@mui/material/styles';
import withAdminAuthorization from '../../hoc/ithAdminAuthorization';
import { useCallback, useEffect, useState } from "react";
import { Box, Button, TextField, Typography, Stepper, Step, StepLabel, RadioGroup, FormControlLabel, Paper, Radio, Dialog, DialogTitle, DialogContent, DialogActions, debounce, Stack, IconButton, Link } from "@mui/material";
import { Controller, set, useForm } from 'react-hook-form';
import OTP from '../../Components/Otp';
import { useAuth } from '../../auth/AuthProvider';
import SnackbarAlert from '../../Components/SnackbarAlert';
import { number } from 'yup';
import { baseURL } from '../../hooks/helper';
import { ArrowBack } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    mode: 'light', // or 'dark'
    background: {
      default: '#fff', // example color
    },
  },
  typography: {
    // fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    // h1: {
    //   fontFamily: 'var(--chakra-fonts-heading)',
    // },
    // h2: {
    //   fontFamily: 'var(--chakra-fonts-heading)',
    // },
    // body1: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // body2: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // button: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // code: {
    //   fontFamily: 'var(--chakra-fonts-mono)',
    // },
  },
});
//const baseURL="http://localhost:8080"

function TruckRegistration() {
  const { control, handleSubmit, watch, formState: { isValid }, setValue } = useForm();
  const [truckNumber, setTruckNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpId, setOtpId] = useState("");
  const [randomizedValue, setRandomizedValue] = useState("");
  const [existingMobile, setExistingMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [truckExists, setTruckExists] = useState(false);
  const [truckExistData, setTruckExistData] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [openUpdateMobileDialog, setOpenUpdateMobileDialog] = useState(false);
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [error, setError] = useState("");
  const { token } = useAuth();

  const [truckData, setTruckData] = useState();


  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(60); // Countdown from 60 seconds

  // Start countdown when component loads
  useEffect(() => {
    if (resendDisabled) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setResendDisabled(false);
            return 60; // Reset timer
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [resendDisabled, timer]);

  const handleResend = () => {
    //handleResendOtp(); // Call API to resend OTP
    sendOtp()
    setResendDisabled(true);
    setTimer(60); // Restart timer
  };



  const handleTruckNumberChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9]*$/; // Allow only alphanumeric
    if (!regex.test(value)) {
      setError("Truck number must be alphanumeric only.");
    } else if (value.length > 10) {
      setError("Truck number must be exactly 10 characters.");
    } else {
      setError("");
      setTruckNumber(value);
      if (value.length === 10) {
        debouncedValidation(value); // Trigger debounce when input reaches 10 characters
      }
    }
  };

  // Debounced validation (e.g., API call or database check)
  const debouncedValidation = useCallback(
    debounce((value) => {
      //setDebouncedTruckNumber(value); // This can trigger API calls
      validateExistingTruck(value);
      console.log("Validating truck number:", value); // Replace with API call
    }, 500), // 500ms debounce time
    []
  );

  const validateExistingTruck = async (truckNumber) => {
    setLoading(true)
    try {
      const response = await fetch(`${baseURL}/api/otp/trucks/search/${truckNumber}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log(data)
      setTruckExists(data.length > 0);
      setTruckExistData(data[0])
    } catch (error) {
      console.error("Error checking truck existence:", error);
      return false;
    }
    setLoading(false)
  }
  const handleGetOtp = async () => {
    setLoading(true);
    await sendOtp(mobileNumber,truckNumber);
    setLoading(false);
  };
  const sendOtp = async (mobileNumber,truckNumber) => {
    try {
      const response = await fetch(`${baseURL}/api/otp/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ phoneNumber: mobileNumber, truckNumber }),
      });

      const data = await response.json();

      if (response.ok) {
        setSnackbar({ open: true, message: "OTP sent successfully!", severity: "success" });
        console.log("OTP sent successfully:", data);
        setOtpId(data.otpId);
        setRandomizedValue(data.randomizedValue);
        setOtpSent(true);
      } else {
        console.error("Error sending OTP:", data);
        alert("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Network error while sending OTP:", error);
      alert("Network error. Please try again.");
    }
    setTimer(60)
    setLoading(false);
  };
  const handleVerifyOtp = async () => {
    setLoading(true);
    if (!otp) {
      alert("Please enter OTP");
      return;
    }
    try {
      const response = await fetch(`${baseURL}/api/otp/validate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          otpId: otpId,
          otpCode: otp,
          randomizedValue: randomizedValue,
          truckUpdate: truckExists
        }),
      });

      const data = await response.json();
      if (data.valid) {
        console.log("OTP Verified:", data);
        fetchTruckDetails(data.truck);
        setOtpVerified(true);
        setSnackbar({ open: true, message: "OTP verified successfully!", severity: "success" });
      } else {
        alert(data.message);
        setSnackbar({ open: true, message: "Invalid OTP!", severity: "error" });
      }
    } catch (error) {
      console.error("OTP verification failed", error);
      alert("Failed to verify OTP. Please try again.");
    }
    setLoading(false);
  };
  const fetchTruckDetails = async (data) => {
    // try {
    //   const response = await fetch(`${baseURL}/trucks/truckNumber?truckNumber=${truckNumber}`, {
    //     method: "GET",
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });

    //   if (!response.ok) {
    //     // If truck is not found (assuming 404 response), populate empty form
    //     console.warn("Truck not found, proceeding with empty form.");
    //     populateEmptyForm();
    //     return;
    //   }

    //   const data = await response.json();
    try{
      console.log("Truck details:", data);
      setTruckData(data)
      // Populate form fields with fetched data
      setValue("truckNumber", data.truckNumber || "");
      if (newMobileNumber) {
        setValue("mobileNumber", newMobileNumber || "");
      } else {
        setValue("mobileNumber", data.regMobileNo || "");
      }
      if(data.verificationId.regUserFirstName){
        setValue("regUserFirstName", data.verificationId.regUserFirstName);
      }
      if(data.verificationId.regUserMmiddleName){
        setValue("regUserMmiddleName", data.verificationId.regUserMmiddleName);
      }
      if(data.verificationId.regUserMobileNo){
        setValue("regUserMobileNo", data.verificationId.regUserMobileNo);
      }       
      if(data.verificationId.regUserLastName){
        setValue("regUserLastName", data.verificationId.regUserLastName);
      } 
    } catch (error) {
      console.error("Network error:", error);
      populateEmptyForm(); // Ensure form is empty if API call fails
    }
  };

  // Function to populate an empty form for new truck entry
  const populateEmptyForm = () => {
    setValue("truckNumber", truckNumber || "");
    setValue("mobileNumber", mobileNumber || "");
    setValue("regUserFirstName", "");
    setValue("regUserMmiddleName", "");
    setValue("regUserLastName", "");
    setValue("regUsermobile", "");

  };
  const onSubmit = async (data) => {
    console.log(data)
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/api/otp/truck/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          truckNumber: truckData.verificationId.id, // Pass truckNumber as is
          regUserFirstName: data.regUserFirstName,
          regUserMiddleName: data.regUserMiddleName || "", // Ensure it handles null values
          regUserLastName: data.regUserLastName || "",
          regUserMobileNo: data.regUserMobileNo, // Handle conditional mobile number
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setSnackbar({ open: true, message: result.message, severity: "success" });
        requestFormReset();
      } else {
        setSnackbar({ open: true, message: result.message || "Failed to update truck!", severity: "error" });
      }
    } catch (error) {
      console.error("Network error:", error);
      setSnackbar({ open: true, message: "Network error!", severity: "warning" });
    }
    setLoading(false);
  };
  const requestFormReset = () => {
    setOtpVerified(false);
    setOtp("");
    setOtpSent(false);
    setTruckNumber("");
    setMobileNumber("");
    setExistingMobile("");
    populateEmptyForm();
    setNewMobileNumber();
    setTruckExists(false);
    setTruckExistData()
    setLoading(false);
  }

  const handleProceedNext = async () => {
    await sendOtp(existingMobile)
  }

  const driverFirstName = watch("regUserFirstName");
  const ownerMobile = watch("regUserMobileNo");
  const isFormValid = driverFirstName && ownerMobile?.length === 10;

  const formatTruckNumber = (input) => {
    const cleaned = input.replace(/[^A-Z0-9]/gi, "").toUpperCase();
    return cleaned.replace(/^(\w{2})(\d{2})(\w{0,2})(\d{0,4})$/, "$1-$2-$3-$4").replace(/-$/, "");
  };

  // const handleTruckNumberChange = (e) => {
  //   setTruckNumber(formatTruckNumber(e.target.value));
  // };

  const generateOtp = async (number) => {
    const response = await fetch(`${baseURL}/api/otp/generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ phoneNumber: number, truckNumber }),
    });
    return response;
  }
  const handleUpdateMobile = async () => {
    setLoading(true);
    try {
      const response = await generateOtp(newMobileNumber);
      const data = await response.json();
      if (response.ok) {
        setSnackbar({ open: true, message: "OTP sent successfully!", severity: "success" });
        console.log("OTP sent successfully:", data);
        setOtpId(data.otpId);
        setRandomizedValue(data.randomizedValue);
        setOtpSent(true);
        setLoading(false);
        setOpenUpdateMobileDialog(false);
      } else {
        console.error("Error sending OTP:", data);
        alert("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      setSnackbar({ open: true, message: "Network error!", severity: "warning" });
    }
    setLoading(false);
  };
  const handleUpdateTruck = async(truckNumber) => {
    console.log("Redirecting to update truck:", truckNumber);
    await sendOtp(truckExistData.regMobileNo,truckNumber);
  };
  const hanldeBack =()=>{
    setOtpSent(false)
    // setTruckNumber("")
    // setMobileNumber("")
    // setOtp("")
    // setOtpId("")
    // setOtpVerified("")
  }
  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3} sx={{ width: "80%", margin: "auto", padding: 4, textAlign: "center", borderRadius: 2, border: "1px solid #ccc" }}>
        <Typography variant="h4" gutterBottom>Truck Registration</Typography>

        <Stepper activeStep={otpVerified ? 2 : otpSent ? 1 : 0} alternativeLabel>
          <Step>
            <StepLabel>Truck Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Verify OTP</StepLabel>
          </Step>
          <Step>
            <StepLabel>Submit Details</StepLabel>
          </Step>
        </Stepper>

        <Box sx={{ marginTop: 4 }}>
          {!otpSent ?
            (<>
              <Box sx={{ display: "flex", gap: 2, marginBottom: 2, justifyContent: "center", alignItems: "center", width: "50%", margin: "auto" }}>
                <TextField
                  label="Truck Number"
                  variant="outlined"
                  fullWidth
                  value={truckNumber}
                  onChange={handleTruckNumberChange}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Reg Mobile No."
                  variant="outlined"
                  fullWidth
                  value={mobileNumber}
                  type='number'
                  length={10}
                  onChange={(e) => setMobileNumber(e.target.value.replace(/\D/g, ""))}
                  sx={{ marginBottom: 2 }}
                />

              </Box>
              <div style={{padding:'10px'}}>
              {truckExists && (
                <Typography color="error">
                  🚛 Truck already exists!
                  <span
                    onClick={() => handleUpdateTruck(truckNumber)}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginLeft: "5px"
                    }}
                  >
                    Update Truck Details
                  </span>
                </Typography>
              )}
              </div>
              <Button variant="contained" color="primary" size='large' onClick={handleGetOtp} disabled={!truckNumber || mobileNumber.length !== 10 || truckExists} loading={loading}>
                Get OTP
              </Button>
            </>
            ) : !otpVerified ? (
              <div>
              {/* Back Button */}
              {/* <Box spacing={2} alignItems="center" sx={{ position: "relative", width: "100%" }}> 
                <IconButton onClick={()=>{}} sx={{ position: "absolute", left: 0 }}>
                  <ArrowBack />
                </IconButton>
              </Box> */}
            
              {/* OTP Input Section */}
              <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">Enter OTP</Typography>
                
                <Typography variant="body2" color="text.secondary">
                  OTP sent successfully to mobile number <b>{`******${mobileNumber?.slice(-2)}`}</b>
                </Typography>
            
                {/* Incorrect Mobile Number Option */}
                <Link component="button" onClick={hanldeBack} variant="body2" sx={{ color: "error.main", mt: -1 }}>
                  Incorrect mobile number?
                </Link>
            
                {/* OTP Input */}
                <OTP separator={<span>-</span>} value={otp} onChange={setOtp} length={6} />
            
                {/* Buttons */}
                <Button variant='contained' onClick={handleVerifyOtp} disabled={otp.length < 6} loading={loading}>
                  Verify OTP
                </Button>
            
                <Button variant="text" onClick={handleResend} disabled={resendDisabled}>
                  {resendDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
                </Button>
              </Box>
            </div>
            ) : (
              //<Paper elevation={2} sx={{ padding: 3, borderRadius: 2, border: "1px solid #ccc" }}>
              <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ textAlign: "left" }}>
                <Typography variant="h6" sx={{ borderBottom: "2px solid #1976d2", paddingBottom: 1, marginBottom: 3 }}>Truck Information</Typography>
                <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                  <Controller name="truckNumber" control={control} defaultValue={truckNumber} render={({ field }) => <TextField {...field} label="Truck Number" fullWidth disabled />} />
                  <Controller name="mobileNumber" control={control} defaultValue={mobileNumber} render={({ field }) => <TextField {...field} label="Reg Mobile No." fullWidth disabled />} />
                </Box>

                <Typography variant="h6" sx={{ borderBottom: "2px solid #1976d2", paddingBottom: 1, marginBottom: 3 }}>Registered user Information</Typography>
                <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                  <Controller name="regUserFirstName" control={control} render={({ field }) => <TextField {...field} label="First Name" fullWidth required variant="outlined" InputLabelProps={{ shrink: true }} />} />
                  <Controller name="regUserMiddleName" control={control} render={({ field }) => <TextField {...field} label="Middle Name" fullWidth />} />
                  <Controller name="regUserLastName" control={control} render={({ field }) => <TextField {...field} label="Last Name" fullWidth />} />
                  <Controller name="regUserMobileNo" control={control} render={({ field }) => <TextField {...field} label="Mobile Number" type='number' fullWidth required variant="outlined" InputLabelProps={{ shrink: true }} />} />
                </Box>
                <Button type="submit" variant="contained" color="primary" disabled={!isFormValid} loading={loading}>Submit</Button>
              </Box>
              // </Paper>
            )}
          <SnackbarAlert
            open={snackbar.open}
            message={snackbar.message}
            severity={snackbar.severity}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          />
        </Box>
        <Dialog open={openUpdateMobileDialog} onClose={() => setOpenUpdateMobileDialog(false)}>
          <DialogTitle>Update Registered Mobile Number</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              Current Mobile Number: <strong>{existingMobile}</strong>
            </Typography>
            <TextField
              label="New Mobile Number"
              fullWidth
              type="tel"
              value={newMobileNumber}
              onChange={(e) => setNewMobileNumber(e.target.value)}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenUpdateMobileDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleUpdateMobile()} color="secondary" variant="contained" loading={loading}>
              Get OTP
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </ThemeProvider>
  );
}

export default withAdminAuthorization(TruckRegistration)
