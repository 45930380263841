import {useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Badge, Box, Button, Chip, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import { createTheme } from '@mui/material/styles';
import { useAuth } from '../../../auth/AuthProvider';
import withAdminAuthorization from '../../../hoc/ithAdminAuthorization';
import { FiEdit, FiMoreVertical, FiTrash2, FiTrip } from 'react-icons/fi';
import useConfirmationDialog, { ConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { useDelete } from '../../../hooks/api-call';
import useCustomToast from "../../../hooks/use-toast";
import { baseURL } from '../../../hooks/helper';
import { ContactPhoneOutlined } from '@mui/icons-material';
import { HStack, Text } from '@chakra-ui/react';
import { CgProfile } from 'react-icons/cg';


const theme = createTheme({
  palette: {
    mode: 'light', // or 'dark'
    background: {
      default: '#fff', // example color
    },
  },
  typography: {
  },
});

export const RowActions = ({ row, handleEditOpen, openConfirmationDialog }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', gap: '20px', width: '100%' }}>
      {/* Edit Button */}
      <Tooltip title="Edit">
        <IconButton onClick={() => handleEditOpen(row)}>
          <FiEdit size={12} color='blue' />
        </IconButton>
      </Tooltip>

      {/* Three Dots Menu */}
      <IconButton onClick={handleMenuOpen}>
        <FiMoreVertical size={14} />
      </IconButton>

      {/* Menu with Delete Button */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem
          onClick={() => {
            openConfirmationDialog(row.original.driverId);
            handleMenuClose();
          }}
          sx={{ color: 'red' }}
        >
          <FiTrash2 size={12} style={{ marginRight: 8 }} />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};
const DriverTable = ({ handleEditOpen, handleAddDriver, selectedTabId, tabs }) => {

  const { token } = useAuth();
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 });
  const { showSuccessToast } = useCustomToast();

  const handleRemoveTrip = async (driverId) => {
    console.log("Driver ID:", driverId);
    try {
      await deleteData(null, `drivers/${driverId}`);
      showSuccessToast("Success", `Driver deleted successfully`);
      refetch();
    } catch (e) {
      console.log(e)
      showSuccessToast("Failed", `Something went wrong.. Failed to delete`);
    }
  };

  const { openConfirmationDialog, ConfirmationDialog } = useConfirmationDialog(handleRemoveTrip);
  const {
    data: { content = [], totalElements } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['trucks-list', { columnFilters, globalFilter, pagination, sorting }],
    queryFn: async () => {
      const fetchURL = new URL(`${baseURL}/drivers/filter`);
      fetchURL.searchParams.set('page', `${pagination.pageIndex}`);
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      let filters = columnFilters.filter(f => f.id !== "stageName");

      // Enforce tab-based filtering
      if (selectedTabId && selectedTabId != 0) {
        const selectedTab = tabs.find(tab => tab.id === selectedTabId);
        console.log(selectedTab)
        if (selectedTab) {
          filters.push({ id: "latestTripStage.stageName", value: selectedTab.stageName });
        }
      }
      fetchURL.searchParams.set('filters', JSON.stringify(filters));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      const response = await fetch(fetchURL.href, { headers: headers });
      return response.json();
    },
    placeholderData: keepPreviousData,
  });
  const { deleteData } = useDelete();
  useEffect(() => {
    console.log("Selected Tab ID Updated:", selectedTabId);
    refetch();
  }, [selectedTabId]);

  const columns = useMemo(() => [
    {
      accessorKey: 'firstName',
      header: (
        <Tooltip title="First Name">
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {/* <LocalShippingIcon size={14} /> */}
            First Name
          </Typography>
        </Tooltip>
      ),
      muiFilterTextFieldProps: {
        placeholder: 'First Name', // Custom placeholder
      },
      Cell: ({ cell }) => {
        return (
          <HStack>

            <CgProfile color='#2b6cb0' />
            {''}
            <Text> {cell.getValue()}</Text>
          </HStack>
        )
      }
    },
    {
      accessorKey: 'middleName',
      header: (
        <Tooltip title="Middle Name">
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {/* <LocalShippingIcon size={14} /> */}
            Middle Name
          </Typography>
        </Tooltip>
      ),
      muiFilterTextFieldProps: {
        placeholder: 'middle Name ', // Custom placeholder
      },
    },
    {
      accessorKey: 'lastName',
      header: (
        <Tooltip title="Last Name">
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {/* <LocalShippingIcon size={14} /> */}
            Last Name
          </Typography>
        </Tooltip>
      ),
      muiFilterTextFieldProps: {
        placeholder: 'last name', // Custom placeholder
      },
    },
    {
      accessorKey: 'contactNo',
      header: (
        <Tooltip title="Contact No.">
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <ContactPhoneOutlined size={14} />
            Contact No.
          </Typography>
        </Tooltip>
      ),
      muiFilterTextFieldProps: {
        placeholder: 'Contact No.', // Custom placeholder
      },
    },
    {
      accessorKey: 'noOfTrips',
      header: 'Trips',
      //   size:0,
      enableColumnFilter: true,
      filterVariant: 'range-slider',
      filterFn: 'betweenInclusive',
      muiFilterSliderProps: {
        marks: true, // Show marks on the slider
        step: 10, // Step size for integer values
        min: 0, // Minimum trip count
        max: 100, // Set a reasonable maximum based on your data
        valueLabelFormat: (value) => `${value} trips`, // Display format
      },
      Cell: ({ cell }) => (
        <Chip label={cell.getValue()} color="secondary" size='small' style={{ height: '20px' }} />)
    },
    {
      accessorKey: 'assignedLevel',
      header: 'Assigned Level',
      enableColumnFilter: true,
      filterVariant: 'select', // Enables dropdown filtering
      filterSelectOptions: [
        { label: 'TRIP LEVEL', value: "Trip Level" },
        { label: 'TRUCK LEVEL', value: "Truck Level" },
        { label: 'UNASSIGNED', value: "Unassigned" },
        { label: 'BOTH', value: 'Both' }
      ],
      Cell: ({ cell }) => {
        const value = cell.getValue();
    
        // Define color based on assignedLevel
        const getColor = (level) => {
          switch (level) {
            case "Trip Level":
              return "success"; // Green
            case "Truck Level":
              return "primary"; // Orange
            case "Unassigned":
              return "default"; // Grey
            case "Both":
              return "secondary"; // Purple
            default:
              return "primary"; // Default color
          }
        };
    
        return (
          <Chip
            label={value?.toUpperCase()}
            color={getColor(value)}
            size="small"
            style={{ height: '20px', fontWeight: 'bold' }}
          />
        );
      }
    }
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: content,
    manualFiltering: true,
    enableColumnPinning: true,
    enableSorting: false,
    manualPagination: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    //manualSorting: true,
    desnity: 'comfortable',
    initialState: { density: 'compact', pagination: { pageIndex: 0, pageSize: 20 }, columnPinning: { left: ['firstName', 'contactNo'], right: ['mrt-row-actions'], } },
    muiToolbarAlertBannerProps: isError ? { color: 'error', children: 'Error loading data' } : undefined,
    muiPaginationProps: {
      rowsPerPageOptions: [20, 25, 50, 100],
      showFirstButton: true,
      showLastButton: true,
      sx: {
        color: "primary", // Text color
        // backgroundColor: "blue", // Background color
        "&.Mui-selected": {
          backgroundColor: "red", // Active page color
          color: "white", // Active page text color
        }
      },
    },
    paginationDisplayMode: 'pages',
    enableEditing: true,
    // editDisplayMode: 'custom',
    renderRowActions: ({ row, table }) => (
         <RowActions  row={row} handleEditOpen={handleEditOpen} openConfirmationDialog={openConfirmationDialog}/>
       ),
    renderRowActionMenuItems: () => [<MenuItem key="action">Action</MenuItem>],
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '3px', width: '100%', marginLeft: '10px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={refetch} >
            <RefreshIcon onClick={refetch} />
          </IconButton>
        </Tooltip>
        <Button size='medium' variant='contained' color='primary' onClick={handleAddDriver} >
          Add Driver
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            table.resetColumnFilters(); // Clear individual column filters
            table.setGlobalFilter('');  // Clear global filter
          }}
        >
          Clear Filters
        </Button>
      </Box>

    ),
    rowCount: totalElements ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,

    },
    muiTableBodyRowProps: ({ staticRowIndex }) => ({
      sx: {
        backgroundColor: staticRowIndex.index % 2 === 0 ? 'white' : 'grey.100',
      },
    })
  });

  return (<>{ConfirmationDialog} <MaterialReactTable table={table} /></>);
};


// function ({tabs,selectedTabId,handleAddTrip}) {
//   const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
//   // const handleEditTripClose = () => {
//   //   setIsEditDrawerOpen(false);
//   //   setEditableTripId(false);
//   // //  fetchTripsData(0);
//   // };

//   const ReactQueryDevtoolsProduction = lazy(() =>
//     import('@tanstack/react-query-devtools/build/modern/production.js').then(
//       (d) => ({
//         default: d.ReactQueryDevtools,
//       }),
//     ),
//   );
// //   useEffect(() => {
// //     console.log("Selected Tab ID Updated:", selectedTabId);
// // }
// // , [selectedTabId]);
//   return (
//         <div style={{ height: '100%', width: '100%', margin: '8px' }}>
//           <Example handleEditOpen={handleEditOpen}  handleAddTrip={handleAddTrip} tabs={tabs} selectedTabId={selectedTabId}/>
//         </div>

//   );
// }
export default withAdminAuthorization(DriverTable)
