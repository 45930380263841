import { lazy, Suspense, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Badge, Box, Button, Chip, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth } from '../../auth/AuthProvider';
import withAdminAuthorization from '../../hoc/ithAdminAuthorization';
import { FiEdit, FiTrash2, FiTruck, FiMoreVertical } from 'react-icons/fi';
import { TruckInfoWithCopy } from './TruckListing';
import { HStack, Text } from '@chakra-ui/react';
import useConfirmationDialog, { ConfirmationDialog } from '../../hooks/useConfirmationDialog';
import { useDelete } from '../../hooks/api-call';
import { Delete, Edit } from '@mui/icons-material';
import TruckDrawer from './drawer-forms/TruckDrawer';
import { CgProfile } from "react-icons/cg";
import useCustomToast from "../../hooks/use-toast";
import { baseURL } from '../../hooks/helper';
import { TripChip } from '../../admin-section/trips/v2/TripTable';

const theme = createTheme({
  palette: {
    mode: 'light', // or 'dark'
    background: {
      default: '#fff', // example color
    },
  },
  typography: {
    // fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    // h1: {
    //   fontFamily: 'var(--chakra-fonts-heading)',
    // },
    // h2: {
    //   fontFamily: 'var(--chakra-fonts-heading)',
    // },
    // body1: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // body2: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // button: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // code: {
    //   fontFamily: 'var(--chakra-fonts-mono)',
    // },
  },
});

export const RowActions = ({ row, handleEditOpen, openConfirmationDialog }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', gap: '20px', width: '100%' }}>
      {/* Edit Button */}
      <Tooltip title="Edit">
        <IconButton onClick={() => handleEditOpen(row)}>
          <FiEdit size={12} color='blue' />
        </IconButton>
      </Tooltip>

      {/* Three Dots Menu */}
      <IconButton onClick={handleMenuOpen}>
        <FiMoreVertical size={14} />
      </IconButton>

      {/* Menu with Delete Button */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem
          onClick={() => {
            openConfirmationDialog(row.original.truckId);
            handleMenuClose();
          }}
          sx={{ color: 'red' }}
        >
          <FiTrash2 size={12} style={{ marginRight: 8 }} />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};
const Truck = ({ handleEditOpen, handleAddTruck }) => {

  const { token } = useAuth();
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 });
  const { showErrorToast, showSuccessToast } = useCustomToast();
  const handleRemoveTruck = async (truckId) => {
    try {
      await deleteData(null, `trucks/${truckId}`);
      showSuccessToast("Success", `Truck deleted successfully`);
      refetch();
    } catch (e) {
      console.log(e)
      showSuccessToast("Failed", `Something went wrong.. Failed to delete`);
    }
  };
  const { openConfirmationDialog, ConfirmationDialog } = useConfirmationDialog(handleRemoveTruck);
  const {
    data: { content = [], totalElements } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['trucks-list', { columnFilters, globalFilter, pagination, sorting }],
    queryFn: async () => {
      const fetchURL = new URL(`${baseURL}/api/trucks`);
      fetchURL.searchParams.set('page', `${pagination.pageIndex}`);
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      const response = await fetch(fetchURL.href, { headers: headers });
      return response.json();
    },
    placeholderData: keepPreviousData,
  });
  const { deleteData } = useDelete();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'truckNumber', header: 'Truck No.',
        //size: 200,
        Cell: ({ cell }) => (
               <TripChip truckNumber={cell.getValue()} />
            )
      },
      {
        accessorKey: 'verificationStatus', header: 'Status',
        //size: 200,
        filterVariant: 'select', // Enables dropdown filtering
        filterSelectOptions: [
          { label: 'PENDING', value: 'PENDING' },
          { label: 'VERIFIED', value: 'VERIFIED' }
        ],
        Cell: ({ cell }) => {
          return (
            <Chip label={cell.getValue() ? cell.getValue() : 'PENDING'} variant='filled' size='small' color={cell.getValue() == 'PENDING' ? 'warning' : 'success'} />
          )
        }
      },
      {
        accessorKey: 'owner.firstName', header: 'Owner Name',
        Cell: ({ cell }) => {
          return (
            <HStack>

              <CgProfile color='#2b6cb0' />
              {''}
              <Text> {cell.getValue()}</Text>
            </HStack>
          )
        }
      },
      {
        accessorKey: 'truckKyc.rcOwnerSr', header: 'Serial No.',
        //size: 200,
        Cell: ({ cell }) => {
          return (
            cell.getValue() ? cell.getValue() : 'NA'
          )
        }
      },
      {
        accessorKey: 'regMobileNo', header: 'Reg No.',
        //size: 200,
        Cell: ({ cell }) => {
          return (
            cell.getValue() ? cell.getValue() : 'N/A'
          )
        }
      },
      // {
      //   accessorKey: 'owner.ownerId', header: 'Owner SerialNo.',
      //   size:100,
      //   Cell: ({ cell }) => {
      //     return (
      //       <HStack>

      //         <Text> {cell.getValue()}</Text>
      //       </HStack>
      //     )
      //   }
      // },
      {
        accessorKey: 'truckHypothecated',
        header: 'Hypothecated',
        size: 50,
        Cell: ({ cell }) => (cell.getValue() ?
          <Chip label="Yes" color="primary" size='small' style={{ height: '20px' }}>Yes</Chip> :
          <Chip color={"warning"} label="No" size='small'>No</Chip>),
        filterVariant: 'checkbox',
      },
      {
        accessorKey: 'driver.firstName', header: 'Driver Name',
        Cell: ({ cell }) => {
          return (
            <HStack>

              <CgProfile color='#ed6c02' />
              {''}
              <Text> {cell.getValue()}</Text>
            </HStack>
          )
        }
      },
      {
        accessorKey: 'noOfTrips',
        header: 'Trips',
        //   size:0,
        enableColumnFilter: true,
        filterVariant: 'range-slider',
        filterFn: 'betweenInclusive',
        muiFilterSliderProps: {
          marks: true, // Show marks on the slider
          step: 10, // Step size for integer values
          min: 0, // Minimum trip count
          max: 100, // Set a reasonable maximum based on your data
          valueLabelFormat: (value) => `${value} trips`, // Display format
        },
        Cell: ({ cell }) => (
          <Chip label={cell.getValue()} color="secondary" size='small' style={{ height: '20px' }} />)
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: content,
    manualFiltering: true,
    enableColumnPinning: true,
    enableSorting: false,
    manualPagination: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    //manualSorting: true,
    desnity: 'comfortable',
    initialState: { density: 'compact', pagination: { pageIndex: 0, pageSize: 20 }, columnPinning: { left: ['truckNumber'], right: ['mrt-row-actions'], } },
    muiToolbarAlertBannerProps: isError ? { color: 'error', children: 'Error loading data' } : undefined,
    muiPaginationProps: {
      rowsPerPageOptions: [20, 25, 50, 100],
      showFirstButton: true,
      showLastButton: true,
      sx: {
        color: "primary", // Text color
        // backgroundColor: "blue", // Background color
        "&.Mui-selected": {
          backgroundColor: "red", // Active page color
          color: "white", // Active page text color
        }
      },
    },
    paginationDisplayMode: 'pages',
    enableEditing: true,
    //editDisplayMode: 'custom',
    renderRowActions: ({ row, table }) => (
      <RowActions row={row} handleEditOpen={handleEditOpen} openConfirmationDialog={openConfirmationDialog} />
    ),
    // renderRowActionMenuItems: () => [<MenuItem key="action">Action</MenuItem>],
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '3px', width: '100%', marginLeft: '10px' }}>
        <Tooltip arrow title="Refresh Data">
          <Button size='small' variant='contained' color='secondary' onClick={refetch} startIcon={<RefreshIcon />}>
            Refresh
          </Button>
        </Tooltip>
        <Button size='small' variant='contained' color='primary' onClick={handleAddTruck} startIcon={<FiTruck />}>
          Add Truck
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            table.resetColumnFilters(); // Clear individual column filters
            table.setGlobalFilter('');  // Clear global filter
          }}
        >
          Clear Filters
        </Button>
      </Box>
    ),
    rowCount: totalElements ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,

    },
    muiTableBodyRowProps: ({ staticRowIndex }) => ({
      sx: {
        backgroundColor: staticRowIndex.index % 2 === 0 ? 'white' : 'grey.100',
      },
    })
  });

  return (<>{ConfirmationDialog} <MaterialReactTable table={table} /></>);
};

const queryClient = new QueryClient();

function TruckTable() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTruckForEdit, setSelectedTruckForEdit] = useState(false)
  const handleAddTruck = async () => {
    console.log("emtry")
    setIsDrawerOpen(true)
  }
  const handleEditOpen = (truck) => {
    console.log("entered", truck.original.truckId)
    setIsDrawerOpen(true);
    setSelectedTruckForEdit(truck.original.truckId);
  };

  const ReactQueryDevtoolsProduction = lazy(() =>
    import('@tanstack/react-query-devtools/build/modern/production.js').then(
      (d) => ({
        default: d.ReactQueryDevtools,
      }),
    ),
  );
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div style={{ height: '100%', width: '100%', margin: '8px' }}>
          <Truck handleEditOpen={handleEditOpen} handleAddTruck={handleAddTruck} />
        </div>

      </ThemeProvider>
      {isDrawerOpen && (
        <TruckDrawer
          selectedTruckForEdit={selectedTruckForEdit}
          setIsDrawerOpen={setIsDrawerOpen}

          //fetchTrucksData={fetchTrucksData}
          setSelectedTruckForEdit={setSelectedTruckForEdit}
        />
      )}
      {/* <Suspense fallback={null}>
        <ReactQueryDevtoolsProduction />
      </Suspense> */}
    </QueryClientProvider>

  );
}
export default withAdminAuthorization(TruckTable)
