import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

const useConfirmationDialog = (deleteItem) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [itemId, setItemId] = useState(null);

  const handleConfirmAction = async () => {
    setIsProcessing(true);
    await deleteItem(itemId);
    setIsProcessing(false);
    setIsOpen(false);
  };

  const openConfirmationDialog = (id) => {
    console.log("Dialog")
    setItemId(id);
    setIsOpen(true);
  };

  const handleClose = () => {
    if (!isProcessing) {
      setIsOpen(false);
    }
  };

  const ConfirmationDialog = (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Delete Item</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isProcessing}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirmAction}
          color="error"
          disabled={isProcessing}
          startIcon={isProcessing ? <CircularProgress size={20} /> : null}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  return {
    isOpen,
    isProcessing,
    openConfirmationDialog,
    ConfirmationDialog,
  };
};

export default useConfirmationDialog;