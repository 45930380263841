import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { BoxWrapper } from "./TripDrawer";
import { PiTruckFill } from "react-icons/pi";
import { FaUserSecret } from "react-icons/fa";
import { useGet, usePost, usePut } from "../../../hooks/api-call";
import EditSubSectionTrip from "./EditSubSectionTrip";
import TruckHistory from "./TruckHistory";
import BasicUsage from "./BasicUsage";
import { convertMillisecondsToDate } from "../../../hooks/helper";
import ExpenseTable from "./ExpenseTable";
import DriverInfoModal from "../v2/TripDriverAssign";
import useCustomToast from "../../../hooks/use-toast";

const AccordionLabel = ({ icon, label }) => {
  return (
    <h2>
      <AccordionButton display={"flex"} justifyContent={"space-between"} px={0}>
        <Box
          bg={"#edf1fa"}
          // width={"fit-content"}
          py={3}
          pr={3}
          borderTopRightRadius={"10px"}
          borderBottomRightRadius={"10px"}
          minW={"250px"}
        >
          <Flex as="span" flex="1" textAlign="left" ml={2}>
            {icon}
            <Heading as="h4" size="md" color="#f97769" ml={3}>
              {label}
            </Heading>
          </Flex>
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
  );
};

const TextLabel = ({ title, value }) => {
  return (
    <Box>
      <Text color="rgba(157, 155, 155, 1)" fontSize={"14px"}>
        {title}
      </Text>
      <Text color="rgba(44, 45, 91, 1)" fontWeight={"700"} fontSize={"16px"}>
        {value}
      </Text>
    </Box>
  );
};
const EditTripDrawer = ({ editabeTripId, onClose }) => {
  const [tripData, setTripData] = useState();
  const [openExpenseDrawer, setOpenExpenseDrawer] = useState(false);
  const [refreshHistory, setRefreshHistory] = useState(false);
  const [truckHistory, setTruckHistory] = useState([]);
  const [toBeUpdateUI, setToBeUpdateUI] = useState();
  const [expenseId, setExpenseId] = useState("");

  const [driverModal, setDriverModal] = useState(false);
  const { postData } = usePost();
  const { putData } = usePut();
  const { fetchData } = useGet();
  const { showSuccessToast } = useCustomToast();

  // Effect for logging response data
  useEffect(() => {
    if (editabeTripId) {
      (async () => {
        const resp = await fetchData(`trips/${editabeTripId}`);
        setTripData(resp);
      })();
    }
  }, [driverModal]);

  const handleExpense = () => {
    setOpenExpenseDrawer(true);
  };

  const handleDriverModal = (modal) => {
    setDriverModal(modal);
    console.log(modal, driverModal)
  }
  const onSubmit = async (formData) => {
    console.log("enterted")
    console.log(formData)
    let driverId = formData.driverId?.value;
    if (formData.selectDriver == 'new' && formData.firstName && formData.firstName.length > 2 && formData.contactNo && formData.contactNo.length === 10) {
      const driver = {
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        contactNo: formData.contactNo,
      }
      driverId = await postData(driver, "driver");
      showSuccessToast("Success", "Driver created successfully");
    } 
    //console.log("driverId", driverId)
     if (driverId) { 
      console.log("driverId", driverId)
      await putData(driverId, `trips/${editabeTripId}/${driverId}`);
      showSuccessToast("Success", "Trip updated successfully");
      handleDriverModal(false);
    }
  }

  return (
    <Drawer isOpen={true} placement="right" onClose={onClose} size="2xl">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton mt={1} />

          <Divider />
          <DrawerBody display={"flex"}>
            <Box width={"50%"}>
              <Flex>
                <Heading as="h4" size="lg">
                  Trip Id: {editabeTripId}
                </Heading>
                <Button variant="ghost" ml={3}>
                  <FiEdit color="blue" />
                </Button>
              </Flex>
              <Box>
                <BoxWrapper>
                  <Accordion allowMultiple>
                    <AccordionItem>
                      <AccordionLabel
                        icon={<PiTruckFill fontSize={"26px"} color="#f97769" />}
                        label="Trip Information"
                      />
                      <AccordionPanel pb={4}>
                        <Flex justifyContent={"space-between"}>
                          <TextLabel
                            title="Truck No."
                            value={tripData?.truck?.truckNumber}
                          />
                          <TextLabel
                            title="Payment to."
                            value={tripData?.paymentTo}
                          />
                          <TextLabel
                            title="Driver Name"
                            value={
                              tripData?.truck?.driver?.firstName +
                              " " +
                              (tripData?.truck?.driver?.middleName || "") +
                              " " +
                              (tripData?.truck?.driver?.lastName || "")
                            }
                          />
                        </Flex>
                        <Flex mt={5} justifyContent={"space-between"}>
                          <TextLabel
                            title="Price per ton."
                            value={tripData?.pricePerTon}
                          />
                          <TextLabel
                            title="Weight (in tons)"
                            value={tripData?.tripQuantity}
                          />
                          <TextLabel
                            title="Ravana No"
                            value={tripData?.ravanaNo}
                          />
                          <TextLabel
                            title="Created date"
                            value={convertMillisecondsToDate(tripData?.createdDate)}
                          />
                        </Flex>
                        <Flex justifyContent={"space-between"}>
                          <TextLabel
                            title="Address."
                            value={
                              (tripData?.order?.buyer?.address || "") +
                              ", " +
                              (tripData?.order?.buyer?.tehsil || "") +
                              ", " +
                              (tripData?.order?.buyer?.state || "") +
                              ", " +
                              (tripData?.order?.buyer?.pincode || "")
                            }
                          />
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionLabel
                        icon={<PiTruckFill fontSize={"26px"} color="#f97769" />}
                        label="Order Information"
                      />
                      <AccordionPanel pb={4}>
                        <Flex justifyContent={"space-between"}>
                          <TextLabel
                            title="Order Id"
                            value={tripData?.order?.orderId}
                          />
                          <TextLabel
                            title="Po Number."
                            value={tripData?.order?.poNumber}
                          />
                          <TextLabel
                            title="Status"
                            value={tripData?.order?.orderStatus}
                          />
                          <TextLabel
                            title="Total qunatity."
                            value={tripData?.order?.totalQuantity}
                          />
                        </Flex>
                        <Flex mt={3} gap={20}>
                          <TextLabel
                            title="Material"
                            value={tripData?.order?.material?.materialName}
                          />
                          <TextLabel
                            title="Created Date"
                            value={convertMillisecondsToDate(tripData?.order?.createdDate)}
                          />
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionLabel
                        icon={<PiTruckFill fontSize={"26px"} color="#f97769" />}
                        label="Driver Information"
                      />
                      <AccordionPanel pb={4}>
                        {tripData?.driver?.driverId ? (
                          <>
                            {/* <Flex justifyContent={"end"}>
                              <Text
                                cursor={"pointer"}
                                _hover={{ textDecoration: "underline" }}
                                color="blue"
                                onClick={() => handleDriverModal(true)}
                              >
                                Edit driver
                              </Text>
                            </Flex> */}

                            <Flex justifyContent={"space-between"}>
                              <TextLabel
                                title="Driver Id"
                                value={tripData?.driver.driverId}
                              />
                              <TextLabel
                                title="Driver Name."
                                value={[
                                  tripData?.driver?.firstName ?? "",
                                  tripData?.driver?.middleName ?? "",
                                  tripData?.driver?.lastName ?? ""
                                ].filter(Boolean).join(" ")}
                              />
                              <TextLabel
                                title="Driver ContactNo."
                                value={tripData?.driver?.contactNo}
                              />
                            </Flex>
                            <Flex mt={3} gap={20}>
                              <TextLabel
                                title="Driver Level"
                                value={tripData?.driver?.assignedLevel}
                              />
                              <TextLabel
                                title="Created Date"
                                value={convertMillisecondsToDate(tripData?.driver?.createdDate)}
                              />
                            </Flex>
                          </>) : (
                          <>
                            <Button onClick={() => handleDriverModal(true)} size='medium' color="primary"> Add driver</Button>
                            <DriverInfoModal onClose={() => handleDriverModal(false)} open={driverModal} onSubmit={onSubmit} />
                          </>
                        )}
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionLabel
                        icon={
                          <FaUserSecret fontSize={"26px"} color="#f97769" />
                        }
                        label="Owner Information"
                      />
                      <AccordionPanel pb={4}>
                        <Flex justifyContent={"space-between"}>
                          <TextLabel
                            title="Name"
                            value={tripData?.truck?.owner?.firstName}
                          />
                          <TextLabel
                            title="Contact no."
                            value={tripData?.truck?.owner?.contactNo}
                          />
                          <TextLabel
                            title="Email Id"
                            value={tripData?.truck?.owner?.emailId}
                          />
                        </Flex>
                        <Flex mt={3}>
                          <TextLabel
                            title="Address."
                            value={
                              (tripData?.order?.owner?.address || "") +
                              ", " +
                              (tripData?.order?.owner?.tehsil || "") +
                              ", " +
                              (tripData?.order?.owner?.state || "") +
                              ", " +
                              (tripData?.order?.owner?.pincode || "")
                            }
                          />
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionLabel
                        icon={
                          <FaUserSecret fontSize={"26px"} color="#f97769" />
                        }
                        label="Buyer Information"
                      />
                      <AccordionPanel pb={4}>
                        <Flex justifyContent={"space-between"}>
                          <TextLabel
                            title="Name"
                            value={tripData?.order?.buyer?.buyerName}
                          />
                          <TextLabel
                            title="Contact no."
                            value={tripData?.order?.buyer?.contactNo}
                          />
                          <TextLabel
                            title="Email Id"
                            value={tripData?.order?.buyer?.emailId}
                          />
                        </Flex>
                        <Flex mt={3}>
                          <TextLabel
                            title="Unloading Address."
                            value={
                              (tripData?.order?.buyer?.address || "") +
                              ", " +
                              (tripData?.order?.buyer?.tehsil || "") +
                              ", " +
                              (tripData?.order?.buyer?.state || "") +
                              ", " +
                              (tripData?.order?.buyer?.pincode || "")
                            }
                          />
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionLabel
                        icon={
                          <FaUserSecret fontSize={"26px"} color="#f97769" />
                        }
                        label="Expenses"
                      />
                      <AccordionPanel pb={4}>
                        <ExpenseTable
                          setExpenseId={(value) => {
                            setExpenseId(value);
                            setOpenExpenseDrawer(true);
                          }}
                          toBeUpdateUI={toBeUpdateUI}
                          editabeTripId={editabeTripId}
                          handleExpense={handleExpense}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </BoxWrapper>
              </Box>
            </Box>
            <Box w={"50%"} mt={10} ml={3}>
              {openExpenseDrawer && (
                <BasicUsage
                  expenseId={expenseId}
                  editabeTripId={editabeTripId}
                  truckHistory={truckHistory}
                  onClose={() => {
                    setToBeUpdateUI(Math.random() * 2030303);
                    setOpenExpenseDrawer(false);
                    setExpenseId("");
                  }}
                />
              )}
              <EditSubSectionTrip
                truckHistory={truckHistory}
                setRefreshHistory={setRefreshHistory}
                editabeTripId={editabeTripId}
                setToBeUpdateUI={() => setToBeUpdateUI(Math.random() * 2030303)}
              />
              <TruckHistory
                setRefreshHistory={setRefreshHistory}
                setTruckHistory={setTruckHistory}
                refreshHistory={refreshHistory}
                editabeTripId={editabeTripId}
              />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default EditTripDrawer;
