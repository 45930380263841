import React, { useState } from "react";
import {
  Box,
  Center,
  ChakraProvider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Upload } from "react-feather";
import NumberInput from "../../../atoms/NumberInput";

const TruckInfo = ({
  activeStep,
  handleChange,
  isEditMode,
  initialValue: {
    truckNumber,
    vehicleClass,
    bodyType,
    makerDescription,
    makerModel,
    engineNo,
    chasisNo,
    unlaidenWeight,
    laidenWeight,
    cubicCapacity,
    hypothecatedTo,
    rcRegistrationAt,
    truckHypothecated,
    rcStatus,
  },
}) => {
  console.log( truckNumber,
    vehicleClass,
    bodyType,
    makerDescription,
    makerModel,
    engineNo,
    chasisNo,
    unlaidenWeight,
    laidenWeight,
    cubicCapacity,
    hypothecatedTo,
    rcRegistrationAt,
    truckHypothecated,
    rcStatus,)
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleFormChange(event);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFormChange = (e) => {
    handleChange(e, "truck");
  };

  const handleRadioButton = (e, name) => {
    handleFormChange({ target: { name, value: e } });
  };

  console.log(truckHypothecated)
  return (
    <Box display={activeStep > 0 ? "none" : "block"}>
      <ChakraProvider>
        <Stack spacing={4}>
          <Flex justify="space-between" gap={3}>
            <FormControl isRequired>
              <FormLabel color={"#191b1c"}>Truck No.</FormLabel>
              <Input
                name="truckNumber"
                type="text"
                onChange={handleFormChange}
                value={truckNumber}
                disabled={isEditMode}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"#191b1c"}>Vehicle Class</FormLabel>
              <Input
                name="vehicleClass"
                type="text"
                onChange={handleFormChange}
                value={vehicleClass}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"#191b1c"}>Body Type</FormLabel>
              <Input
                name="bodyType"
                type="text"
                onChange={handleFormChange}
                value={bodyType}
              />
            </FormControl>
          </Flex>
          <Flex justify="space-between" gap={3}>
            <FormControl>
              <FormLabel color={"#191b1c"}>Maker Description</FormLabel>
              <Input
                name="makerDescription"
                type="text"
                onChange={handleFormChange}
                value={makerDescription}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"#191b1c"}>Maker Model</FormLabel>
              <Input
                name="makerModel"
                type="text"
                onChange={handleFormChange}
                value={makerModel}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"#191b1c"}>Engine No.</FormLabel>
              <Input
                name="engineNo"
                type="text"
                onChange={handleFormChange}
                value={engineNo}
              />
            </FormControl>
          </Flex>
          <Flex justify="space-between" gap={3}>
            <FormControl>
              <FormLabel color={"#191b1c"}>Chassis No.</FormLabel>
              <Input
                name="chasisNo"
                type="text"
                onChange={handleFormChange}
                value={chasisNo}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"#191b1c"}>Unladen Weight (ULW) (KG)</FormLabel>
              <Input
                name="unlaidenWeight"
                onChange={handleFormChange}
                value={unlaidenWeight}
                  type="number"
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"#191b1c"}>Laden Weight (KG)</FormLabel>
              <Input
                name="laidenWeight"
                onChange={handleFormChange}
                value={laidenWeight}
                  type="number"
              />
            </FormControl>
          </Flex>
          <Flex justify="space-between" gap={3}>
            <FormControl>
              <FormLabel color={"#191b1c"}>Cubic Capacity (cc)</FormLabel>
              <Input
                name="cubicCapacity"
                onChange={handleFormChange}
                value={cubicCapacity}
                type="number"
              />
            </FormControl>
            <FormControl>
              <FormLabel color={"#191b1c"}>Allowed wt</FormLabel>
              <Input name="allowedWeight" type="number" value={laidenWeight-unlaidenWeight}/>
            </FormControl>
            <FormControl>
              <FormLabel color={"#191b1c"}>RC Registration at</FormLabel>
              <Input
                name="rcRegistrationAt"
                type="text"
                onChange={handleFormChange}
                value={rcRegistrationAt}
              />
            </FormControl>
          </Flex>
          <Flex justify="space-between" gap={3}>
            <FormControl>
              <FormLabel color={"#191b1c"}>Truck Hypothecated</FormLabel>
              <RadioGroup
                defaultValue={(truckHypothecated || "").toString() || "false"}
                value={truckHypothecated+""}
                name="truckHypothecated"
                onChange={(e) => handleRadioButton(e, "truckHypothecated")}
              >
                <Stack direction="row">
                  <Radio value="true">Yes</Radio>
                  <Radio value="false">No</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl >
              <FormLabel color={"#191b1c"}>RC Status</FormLabel>
              <RadioGroup
                defaultValue={rcStatus || ""}
                name="rcStatus"
                onChange={(e) => handleRadioButton(e, "rcStatus")}
              >
                <Stack direction="row">
                  <Radio value="active">Active</Radio>
                  <Radio value="Inactive">Inactive</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel color={"#191b1c"}>Hypothecated To</FormLabel>
              <Input
                name="hypothecatedTo"
                type="text"
                onChange={handleFormChange}
                value={hypothecatedTo}
              />
            </FormControl>
          </Flex>
          <FormControl>
            <FormLabel>Truck Documents</FormLabel>
            <Center
              w="full"
              h="120px"
              borderWidth="1px"
              borderStyle="dashed"
              borderColor="blue.200"
              borderRadius="md"
              position="relative"
              onDrop={handleFileDrop}
              onDragOver={handleDragOver}
            >
              {selectedFile ? (
                <Text>{selectedFile.name}</Text>
              ) : (
                <>
                  <input
                    type="file"
                    id="truckDocuments"
                    name="truckDocuments"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    disabled
                  />
                  <label htmlFor="truckDocuments">
                    <Flex alignItems={"center"}>
                      <Upload color="blue" size={30} />
                      <Box ml={2}>
                        <Text fontSize="xl" color={"blue"}>
                          Upload Document
                        </Text>
                        <Text>Select from system and drag and drop here</Text>
                      </Box>
                    </Flex>
                  </label>
                </>
              )}
            </Center>
          </FormControl>
        </Stack>
      </ChakraProvider>
    </Box>
  );
};

export default TruckInfo;
