import { useCallback, useEffect, useState } from "react";
import { Card, CardContent, Tabs, Tab, Box, ThemeProvider, createTheme } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAuth } from "../../../auth/AuthProvider";
import useCustomToast from "../../../hooks/use-toast";
import TripDrawer from "./DriverDrawer";
import EditTripDrawer from "../../../admin-section/trips/drawer-contents/EditTripDrawer";
import { useQueryClient } from "@tanstack/react-query";
import { baseURL } from "../../../hooks/helper";
import DriverTable from "./DriverTable";
import DriverDrawer from "./DriverDrawer";
import { useGet, usePost, usePut } from "../../../hooks/api-call";

const theme = createTheme({
    palette: {
        mode: 'light',
        background: { default: '#fff' },
    },
});

const BuyerSectionDriver = () => {
    const [tabs, setTabs] = useState([]);
    const [selectedTabId, setSelectedTabId] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [editableDriverId, setEditableDriverId] = useState(null);
    const [selectedTruckForEdit, setSelectedTruckForEdit] = useState(false);
    const [formEditData, setFormEditData] = useState(null);

    const { showSuccessToast } = useCustomToast();
    const { postData } = usePost();
    const { putData } = usePut();
    const { fetchData } = useGet();
    const queryClient = useQueryClient();

    const handleAddDriver = useCallback(() => {
        setIsDrawerOpen(true);
        setSelectedTruckForEdit(false);
        setEditableDriverId(null);
        setFormEditData(null);
    }, []);

    const handleEditOpen = useCallback(async (driver) => {
        try {
            const driverId = driver.original.driverId;
            console.log("driverId", driverId);

            const response = await fetchData(`driver/${driverId}`);
            console.log("response", response);

            setFormEditData(response);
            setEditableDriverId(driverId);
            setIsDrawerOpen(true);
        } catch (error) {
            console.error("Error fetching driver details:", error);
        }
    }, [fetchData]);

    const onSubmit = useCallback(async (driver) => {
        try {
            if (editableDriverId) {  // If driverId exists, update (PUT)
                await putData(driver, `drivers/${editableDriverId}`);
                showSuccessToast("Success", "Driver updated successfully");
            } else {  // Otherwise, create new (POST)
                await postData(driver, "driver");
                showSuccessToast("Success", "Driver created successfully");
            }
    
            queryClient.invalidateQueries(["driver-list"]);
            setIsDrawerOpen(false);
            setEditableDriverId(null);
            setFormEditData(null);
        } catch (error) {
            console.error("error", error);
            showSuccessToast("Failed", error.response.data);
        }
    }, [postData, putData, editableDriverId, showSuccessToast, queryClient]);
    return (
        <ThemeProvider theme={theme}>
            <Card>
                <CardContent>
                    <DriverTable 
                        tabs={tabs} 
                        selectedTabId={selectedTabId} 
                        handleAddDriver={handleAddDriver} 
                        handleEditOpen={handleEditOpen} 
                    />
                </CardContent>
            </Card>
            {isDrawerOpen && (
                <DriverDrawer
                    setIsDrawerOpen={setIsDrawerOpen}
                    editableDriverId={editableDriverId}
                    onSubmit={onSubmit}
                    initialValue={formEditData}
                />
            )}
        </ThemeProvider>
    );
};

export default BuyerSectionDriver;