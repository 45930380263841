import React from "react";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiCopy,
  FiEdit,
  FiTrash2,
  FiTruck,
} from "react-icons/fi";
import useConfirmationDialog from "../../hooks/use-confimation-dialog";

export const TruckInfoWithCopy = ({ truckNumber }) => {
  const toast = useToast();

  const handleCopyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast({
        title: "Copied to clipboard",
        description: `${text} has been copied to your clipboard.`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to copy",
        description: "Unable to copy text to clipboard.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Button size="sm" onClick={() => handleCopyToClipboard(truckNumber)}>
      <FiCopy />
    </Button>
  );
};

const TruckStatusTag = ({ status }) => {
  const isActive = status === "active";

  return (
    <Tag
      width={"100px"}
      border="1px"
      borderColor={isActive ? "green.200" : "red.900"}
      color={isActive ? "black" : "red.100"}
      bg={isActive ? "green.100" : "red.500"}
    >
      {status}
    </Tag>
  );
};

const TruckListing = ({
  isLoading,
  trucks,
  handleEditOpen,
  handleRemoveTruck,
  activePage,
  setActivePage,
  totalPages,
  fetchTrucksData,
}) => {
  const { openConfirmationDialog, Dialog } =
    useConfirmationDialog(handleRemoveTruck);

  const handleNextPage = () => {
    if (totalPages > activePage) {
      const page = activePage + 1;
      setActivePage(page);
      fetchTrucksData(page);
    }
  };

  const handlePrevPage = () => {
    if (activePage > 1) {
      const page = activePage - 1;
      setActivePage(page);
      fetchTrucksData(page);
    }
  };

  return (
    <>
      {Dialog}
      <TableContainer
        w="100%"
        borderRadius={"md"}
        border="1px"
        borderColor="gray.200"
        bg="whiteAlpha.600"
        mt="6"
      >
        {isLoading ? (
          <Box
            h={"60vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Spinner size="xl" />
          </Box>
        ) : (
          <Table size={"sm"}>
            <Thead bg="blackAlpha.50">
              <Tr>
                <Th>Truck No.</Th>
                <Th>Owner</Th>
                <Th>Driver Name</Th>
                <Th>No. of trips</Th>
                {/* <Th>RC Status</Th> */}
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {trucks?.map((truck) => (
                <Tr key={truck.truckId}>
                  <Td>
                    <HStack>
                      <FiTruck />{" "}
                      <Text color="blackAlpha.600">{truck.truckNumber}</Text>
                      <TruckInfoWithCopy truckNumber={truck.truckNumber} />
                    </HStack>
                  </Td>
                  <Td>{`${truck?.owner?.firstName} ${
                    truck?.owner?.middleName || ""
                  } ${truck?.owner?.lastName || ""}`}</Td>
                  <Td>{`${truck?.driver?.firstName} ${truck?.driver?.lastName || ""}`}</Td>
                  <Td> {`${truck.noOfTrips}`} </Td>

                  {/* <Td>
                    <TruckStatusTag status={truck.rcStatus} />
                  </Td> */}
                  <Td>
                    <Button
                      variant="ghost"
                      onClick={() => handleEditOpen(truck)}
                    >
                      <FiEdit color="blue" />
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => openConfirmationDialog(truck.truckId)}
                    >
                      <FiTrash2 color="red" />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
      <Box style={{ textAlign: "right" }} w="100%" px="5" my="3">
        <Button disabled={activePage < 2} onClick={handlePrevPage} width={'150px'}>
          Previous
        </Button>
        <span style={{ margin: "0 0.5rem" }}>
          Page {activePage} of {totalPages}
        </span>
        <Button disabled={totalPages === activePage} onClick={handleNextPage} width={'150px'}>
          Next
        </Button>
      </Box>
      {/* <Box style={{ textAlign: "right" }} w="100%" px="5" mt="3">
        <Button
          disabled={activePage < 2}
          onClick={handlePrevPage}
          width={"150px"}
          colorScheme='gray'
        >
          Previous
        </Button>
        <span style={{ margin: "0 0.5rem" }}>
          Page {activePage} of {totalPages}
        </span>
        <Button
          disabled={totalPages === activePage}
          onClick={handleNextPage}
          width={"150px"}
          colorScheme='gray'
        >
          Next
        </Button>
      </Box> */}
    </>
  );
};

export default TruckListing;
