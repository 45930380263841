import React from "react";
import { Box, Flex, Link, Text, Divider } from "@chakra-ui/react";
import {
  FiGrid,
  FiSettings,
  FiShoppingCart,
  FiTruck,
  FiMapPin,
  FiUsers,
  FiUser,
  FiLogOut,
} from "react-icons/fi";
import { useAuth } from "../auth/AuthProvider";
import { useLocation } from "react-router-dom";

const SidebarLink = ({ isActive, href, icon, label }) => {
  return (
    <Link
      href={href}
      display="flex"
      alignItems="center"
      gap={2}
      px={3}
      py={2}
      fontSize="14px"
      borderRadius="md"
      transition="background 0.2s ease-in-out"
      bg={isActive ? "blue.700" : "transparent"}
      color={isActive ? "white" : "gray.300"}
      fontWeight={isActive ? "bold" : "normal"}
      _hover={{ bg: "blue.600", color: "white" }}
    >
      {icon}
      <Text>{label}</Text>
    </Link>
  );
};

const LoggedNavigation = ({ accessPages }) => {
  const location = useLocation();
  const { logout } = useAuth();

  const isActiveRoute = (route) => location.pathname === route;

  return (
    <Box
      bg="blue.900"
      color="white"
      minH="100vh"
      w="220px"
      boxShadow="lg"
      p={3}
    >
      {/* Logo Section */}
      <Flex justify="center" mb={5}>
       <img src="https://drlime.in/wp-content/uploads/2022/06/logo-drlime.png" size="100px" width={"125px"}/>
        
        </Flex>

      {/* Navigation Links */}
      <Flex direction="column" gap={1}>
        <SidebarLink
          isActive={isActiveRoute("/dashboard")}
          href="/dashboard"
          icon={<FiGrid />}
          label="Dashboard"
        />
        {accessPages?.includes("order") && (
          <SidebarLink
            isActive={isActiveRoute("/order")}
            href="/order"
            icon={<FiShoppingCart />}
            label="Orders"
          />
        )}
        {accessPages?.includes("driver") && (
          <SidebarLink
            isActive={isActiveRoute("/driver")}
            href="/driver"
            icon={<FiTruck />}
            label="Driver"
          />
        )}
        {accessPages?.includes("trip") && (
          <SidebarLink
            isActive={isActiveRoute("/trip")}
            href="/trip"
            icon={<FiMapPin />}
            label="Trips"
          />
        )}
        {accessPages?.includes("sellers") && (
          <SidebarLink
            isActive={isActiveRoute("/sellers")}
            href="/sellers"
            icon={<FiUsers />}
            label="Sellers"
          />
        )}
        {accessPages?.includes("buyer") && (
          <SidebarLink
            isActive={isActiveRoute("/buyer")}
            href="/buyer"
            icon={<FiUser />}
            label="Buyers"
          />
        )}
        {accessPages?.includes("truckV2") && (
          <SidebarLink
            isActive={isActiveRoute("/truckv2")}
            href="/truckV2"
            icon={<FiTruck />}
            label="TrucksV2"
          />
        )}
        {accessPages?.includes("truck-registration") && (
          <SidebarLink
            isActive={isActiveRoute("/truck-registration")}
            href="/truck-registration"
            icon={<FiTruck />}
            label="Truck Reg"
          />
        )}
      </Flex>

      {/* Divider */}
      <Divider my={4} borderColor="gray.600" />

      {/* Footer Links */}
      <Flex direction="column" gap={1}>
        <SidebarLink
          isActive={isActiveRoute("/feedback")}
          href="/feedback"
          icon={<FiUser />}
          label="Feedback"
        />
        <SidebarLink
          isActive={isActiveRoute("/settings")}
          href="/settings"
          icon={<FiSettings />}
          label="Settings"
        />
        <Flex
          align="center"
          gap={2}
          px={3}
          py={2}
          fontSize="14px"
          borderRadius="md"
          cursor="pointer"
          transition="background 0.2s ease-in-out"
          _hover={{ bg: "red.600", color: "white" }}
          onClick={logout}
        >
          <FiLogOut />
          <Text>Logout</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default LoggedNavigation;
