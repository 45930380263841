import React, { Suspense } from "react";
import {
  Box,
  ChakraProvider,
  ColorModeProvider,
} from "@chakra-ui/react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./Pages/Login";
import { AuthProvider, useAuth } from "./auth/AuthProvider";
import TruckDashboard from "./Pages/trucks/TruckDashboard";
import PrivateRoute from "./atoms/PrivateRoute";
import NotFoundPage from "./atoms/NotFound";
import PublicRoute from "./atoms/PublicRoute";
import BuyerDashboard from "./Pages/buyers/BuyerDashboard";
import SellerDashboard from "./Pages/sellers/SellerDashboard";
import Feedback from "./Pages/feedback";
import RolesBasedTrips from "./roles-based-components/RolesBasedTrips";
import RoleBasedOrders from "./roles-based-components/RoleBasedOrders";
import RoleBasedDashboard from "./roles-based-components/RoleBasedDashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TruckTable from "./Pages/trucks/TruckTable";
import { createTheme, ThemeProvider } from "@mui/material";
import TruckRegistration from "./Pages/truck-registration/TruckRegistration";
import BuyerSectionDriver from "./buyer-section/driver/v2/DriverTabs";

const Layout = ({ children }) => {
  return <Box>{children}</Box>;
};
// const ReactQueryDevtoolsProduction = lazy(() =>
//   import('@tanstack/react-query-devtools').then((d) => ({
//     default: d.ReactQueryDevtools,
//   }))
// );
const theme = createTheme({
  palette: {
    mode: 'light', // or 'dark'
    background: {
      default: '#fff', // example color
    },
  },
  typography: {
    // fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    // h1: {
    //   fontFamily: 'var(--chakra-fonts-heading)',
    // },
    // h2: {
    //   fontFamily: 'var(--chakra-fonts-heading)',
    // },
    // body1: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // body2: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // button: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // code: {
    //   fontFamily: 'var(--chakra-fonts-mono)',
    // },
  },
});
const queryClient = new QueryClient();
const App = () => {
  const { token, rohan, isAuthenticated } = useAuth();

  return (
    // <QueryClientProvider client={queryClient}>
    //   <Suspense fallback={null}>
    //     <ReactQueryDevtoolsProduction />
    //   </Suspense>
    <QueryClientProvider client={queryClient}> 
      <ChakraProvider>
        <ColorModeProvider
          options={{
            initialColorMode: "light",
            useSystemColorMode: false,
          }}
        >
          <AuthProvider
            token={token}
            rohan={rohan}
            isAuthenticated={isAuthenticated}
          >
            <Layout>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate to={"/login"} />
                  }
                />
                <Route element={<PublicRoute />}>
                  <Route path="/login" element={<Login />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<RoleBasedDashboard />} />
                  <Route path="/order" element={<RoleBasedOrders />} />
                  <Route path="/driver" element={<BuyerSectionDriver />} />
                  <Route path="/truckV2" element={< TruckTable/>} />
                  {/* <Route path="/trip" element={<DraggableTabs />} /> */}
                  <Route path="/trip" element={<RolesBasedTrips />} />
                  <Route path="/buyer" element={<BuyerDashboard />} />
                  <Route path="/sellers" element={<SellerDashboard />} />
                  <Route path="/feedback" element={<Feedback />} />
                
                  <Route path="/truck-registration" element={< TruckRegistration/>} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Layout>
          </AuthProvider>
        </ColorModeProvider>
      </ChakraProvider>
   
   </QueryClientProvider>
  );
};

export default App;
