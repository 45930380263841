import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid2,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useForm, Controller, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import AsyncSelect from "react-select/async"; // For dynamic truck number selection
import { useGet, usePost } from "../../../hooks/api-call";
import useCustomToast from "../../../hooks/use-toast";
import { useYupValidationResolver } from "../../../hooks/use-validation-resolver";

import { CloseButton, ModalCloseButton } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { baseURL } from "../../../hooks/helper";
import NumberInput from "../../../atoms/NumberInput";
import AddressDetails from "./AddressDetails";
import PhoneNumberInput from "../../../atoms/PhoneNumberInput";
import { useAuth } from "../../../auth/AuthProvider";



const DriverDrawer = ({ setIsDrawerOpen, onSubmit, initialValue }) => {
    const { token } = useAuth();
    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const myRef = useRef(null);
    const queryClient = useQueryClient();
    const validationSchema = (initialContactNo) =>
        Yup.object().shape({
          firstName: Yup.string().required("First name is required"),
          contactNo: Yup.string()
            .required("Contact number is required")
            .matches(/^[0-9]{10}$/, "Must be a valid 10-digit phone number")
            .test("is-unique", "Contact number already exists", async (value) => {
              if (!value) return false; // Ensure value is present
              if (value === initialContactNo) return true; // ✅ Skip validation if unchanged in edit form
      
              try {
                const response = await fetch(
                  `${baseURL}/drivers/checkForDuplicates?contactNumber=${value}`,
                  { headers: headers }
                );
                const data = await response.json();
                return !data; // Return true if available, false if already exists
              } catch (error) {
                console.error("Error validating contact number:", error);
                return false; // Assume invalid if API fails
              }
            }),
        });

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isDirty, isValid },
        getValues, watch,
    } = useForm({
        resolver: useYupValidationResolver(validationSchema(initialValue?.contactNo)
        ),
        mode: "onBlur",
        reValidateMode: "onChange", // Revalidate when user types
        defaultValues: initialValue || {},
    });

    const onClose = () => {
        setIsDrawerOpen(false);
        reset();
    };

    useEffect(() => {
        myRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);
    useEffect(() => {
        console.log(errors.contactNo);
    }, [errors]);
    return (
        <Drawer anchor="right" open={true} onClose={onClose} sx={{ "& .MuiDrawer-paper": { width: "60%" } }}>
            <Box sx={{ p: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 2 }}>
                    <Typography variant="h6" fontWeight="bold">New Driver</Typography>
                    <IconButton onClick={onClose} sx={{ color: "black" }}>
                        <CloseButton size={'small'} />
                    </IconButton>
                </Box>
                <Divider sx={{ my: 2 }} />

                <form onSubmit={handleSubmit(onSubmit)}>

                    <Container ref={myRef} sx={{ display: "block", mt: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#333", p: 1, mb: 2, borderLeft: "4px solid #1976d2", bgcolor: "#f0f0f0", pl: 2 }}>
                            Personal Details
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#9d9b9b", mb: 2 }}></Typography>
                        <Grid2 container spacing={2}>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth required label="First Name" error={!!errors.firstName} helperText={errors.firstName?.message} {...field} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="middleName"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth label="Middle Name" {...field} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth label="Last Name" {...field} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="dob"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth type="date" label="Date of Birth" {...field} InputLabelProps={{ shrink: true }} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="fatherName"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth label="Father's Name" {...field} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="contactNo"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth label="Contact No." {...field} error={!!errors.contactNo} helperText={errors.contactNo?.message} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="martialStatus"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField fullWidth select label="Marital Status" {...field} size="medium" error={!!errors.martialStatus} helperText={errors.martialStatus?.message}>
                                            <MenuItem value="single">Single</MenuItem>
                                            <MenuItem value="married">Married</MenuItem>
                                            <MenuItem value="divorced">Divorced</MenuItem>
                                            <MenuItem value="widowed">Widowed</MenuItem>
                                        </TextField>
                                    )}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="spouseName"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth label="Spouse Name" {...field} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="kids"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth label="Number of Kids" {...field} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="marriageAnniversary"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth label="Marriage Anniversary" type="date" InputLabelProps={{ shrink: true }} {...field} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="preferredLanguages"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth label="Preferred Language" {...field} />}
                                />
                            </Grid2>
                            <Grid2 size={4} sm={4}>
                                <Controller
                                    name="homeCity"
                                    control={control}
                                    render={({ field }) => <TextField fullWidth label="Home City" {...field} />}
                                />
                            </Grid2>
                        </Grid2>
                        <AddressDetails control={control} errors={errors} />
                        <Box sx={{ display: "flex", alignItems: "center", mt: 2, justifyContent: "flex-end" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                sx={{ mt: 2, mb: 2 }}
                                disabled={!isDirty || !isValid}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onClose}
                                sx={{ mt: 2, mb: 2, ml: 2 }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Container>
                </form>
            </Box>
        </Drawer>
    );
};

export default DriverDrawer;