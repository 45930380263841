import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import TruckFilters from "./TruckFilters";
import { useDelete, useGet } from "../../hooks/api-call";
import TruckListing from "./TruckListing";
import TruckDrawer from "./drawer-forms/TruckDrawer";
import TruckSearchHeading from "./TruckSearchHeading";
import withAdminAuthorization from "../../hoc/ithAdminAuthorization";

const TruckDashboard = () => {
  const [activePage, setActivePage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTruckForEdit, setSelectedTruckForEdit] = useState(false);

  const { isLoading, responseData, fetchData } = useGet(
    `trucksWithPagination?pageNumber=${activePage}&rowsPerPage=${pageSize}&truckHypothecated=${""}`
  );

  const { deleteData } = useDelete();

  const fetchTrucksData = (activePage, details) => {
    const params = new URLSearchParams({
        pageNumber: activePage,
        rowsPerPage: pageSize
    });

    if (details?.ownerId) params.append("ownerId", details.ownerId);
    if (details?.driverId) params.append("driverId", details.driverId);
    if (details?.truckHypothecated !== undefined && details?.truckHypothecated !== null) {
        params.append("truckHypothecated", details.truckHypothecated);
    }

    const url = `trucksWithPagination?${params.toString()}`;
    fetchData(url);
};

  const handleRemoveTruck = (truckId) => {
    deleteData(null, `trucks/${truckId}`);
    fetchTrucksData(activePage);
  };

  const handleEditOpen = (truck) => {
    setIsDrawerOpen(true);
    setSelectedTruckForEdit(truck.truckId);
  };

  return (
    <Box>
      <Box pl="5" pr="5">
        <TruckSearchHeading setIsDrawerOpen={setIsDrawerOpen} />
        <TruckFilters
          setIsDrawerOpen={setIsDrawerOpen}
          fetchTrucksData={fetchTrucksData}
          isDrawerOpen={isDrawerOpen}
        />
        <TruckListing
          handleRemoveTruck={handleRemoveTruck}
          isLoading={isLoading}
          trucks={responseData?.content}
          activePage={activePage}
          setActivePage={setActivePage}
          pageSize={pageSize}
          totalPages={responseData?.totalPages}
          fetchTrucksData={fetchTrucksData}
          handleEditOpen={handleEditOpen}
        />
      </Box>
      {isDrawerOpen && (
        <TruckDrawer
          selectedTruckForEdit={selectedTruckForEdit}
          setIsDrawerOpen={setIsDrawerOpen}
          fetchTrucksData={fetchTrucksData}
          setSelectedTruckForEdit={setSelectedTruckForEdit}
        />
      )}
    </Box>
  );
};

export default withAdminAuthorization(TruckDashboard);
