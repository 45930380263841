import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid2,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useForm, Controller, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import AsyncSelect from "react-select/async"; // For dynamic truck number selection
import { useGet, usePost } from "../../../hooks/api-call";
import useCustomToast from "../../../hooks/use-toast";
import { useYupValidationResolver } from "../../../hooks/use-validation-resolver";
import { FaClosedCaptioning } from "react-icons/fa";
import { CloseButton, ModalCloseButton } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { baseURL } from "../../../hooks/helper";

const validationSchema = Yup.object().shape({
    ownerId: Yup.string().required("Please choose a buyer"),
    poNumber: Yup.string().required("Po number is required"),
    pricePerTon: Yup.number().nullable().positive("Must be positive").notRequired(),
    paymentTo: Yup.string().required("Payment to is required"),
    truckId: Yup.object().required("Truck No. is required"),
    weight: Yup.number().nullable().positive("Must be positive").notRequired(),
    tdsId: Yup.string().required("TDS is required"),
    gstId: Yup.string().required("GST is required"),
});



const TripDrawer = ({ setIsDrawerOpen, fetchStageWiseCount,handleRefeth }) => {
    const [truckDetail, setTruckDetail] = useState(null);
    const [selectedBuyerId, setSelectedBuyerId] = useState(null);
    const [poNumbers, setPoNumbers] = useState([]);

    const { responseData: ownernames } = useGet(`buyers`);
    const { responseData: tdsPercantages } = useGet(`getAllTdsPercentage` );
    const { responseData: gstPercantages } = useGet(`getAllGstPercentage`);
    const { fetchData: fetchOrders } = useGet();
    const { postData } = usePost();
    const { showSuccessToast } = useCustomToast();
    const [options, setOptions] = useState([]);

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors, isDirty, isValid },
        register
    } = useForm({
        resolver: useYupValidationResolver(validationSchema),
        mode: "onBlur",
    });

    const onClose = () => {
        setIsDrawerOpen(false);
        reset({});
    };
    const queryClient = useQueryClient(); 
    const onSubmit = async (data) => {
        console.log(data);
        const payload = {
            orderId: data.poNumber,
            truckId: data.truckId.truckId,
            paymentTo: data.paymentTo,
            ravanaNo: data.ravanaNo,
            pricePerTon: data.pricePerTon,
            driverId: data.truckId.driver.driverId,
            tripQuantity: data.weight,
            gstId: data.gstId,
            tdsId: data.tdsId,
        };
        await postData(payload, "trip");
        showSuccessToast("Success", `Trip created successfully`);
        onClose();
      //  fetchStageWiseCount(`trips/stageWiseCount`);
      queryClient.invalidateQueries(['trucks-list']);
    };

    useEffect(() => {
        if (selectedBuyerId) {
            (async () => {
                const resp = await fetchOrders(`buyer/${selectedBuyerId}/orders?buyerId=${selectedBuyerId}`);
                setPoNumbers(resp);
            })();
        } else {
            setPoNumbers([]);
        }
    }, [selectedBuyerId]);

    // Fetch truck numbers based on user input
    const loadTruckOptions = async (inputValue) => {
        if (!inputValue) return [];
        if(inputValue.length < 3) return [];
        const response = await fetchOrders(`${baseURL}/trucks/search?query=${inputValue}`);
        return response.map((truck) => ({
            value: truck.truckId,
            label: truck.truckNumber,
            driver: truck.driver, // Store driver info
            ...truck,
        }));
    };

    const customStyles = {
        control: base => ({
            ...base,
            height: 35,
            minHeight: 35
        })
    };
    return (
        <Drawer anchor="right" open={true} onClose={onClose} sx={{ "& .MuiDrawer-paper": { width: "60%" } }}>
            <Box sx={{ p: 2 }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // bgcolor: "#1976d2",
                        ///color: "white",
                        p: 2,
                    }}
                >
                    <Typography variant="h6" fontWeight="bold">New Trip</Typography>
                    <IconButton onClick={onClose} sx={{ color: "black" }}>
                        <CloseButton  size={'small'}/>
                    </IconButton>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: "bold",
                        color: "#333",
                        p: 1,
                        mb: 2,
                        borderLeft: "4px solid #1976d2",
                        bgcolor: "#f0f0f0",
                        pl: 2,
                    }}
                >
                    Order Information
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid2 container spacing={2}>
                        <Grid2 item size={4} sm={4}>
                            <FormControl fullWidth error={!!errors.ownerId}>
                                <InputLabel>Select Buyer</InputLabel>
                                <Controller
                                    required
                                    label="Select Buyer"
                                    name="ownerId"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            label="Select Buyer"
                                            {...field}
                                            required
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setSelectedBuyerId(e.target.value);
                                                setValue("poNumber", "");

                                            }}
                                        >
                                            {ownernames?.map((owner) => (
                                                <MenuItem key={owner.buyerId} value={owner.buyerId}>
                                                    {owner.buyerName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.ownerId && <Typography color="error">{errors.ownerId.message}</Typography>}
                            </FormControl>
                        </Grid2>

                        <Grid2 size={4} sm={4}>
                            <FormControl fullWidth error={!!errors.poNumber}>
                                <InputLabel>Po Number</InputLabel>
                                <Controller
                                    name="poNumber"
                                    control={control}
                                    render={({ field }) => (
                                        <Select {...field} disabled={!poNumbers.length} label="Po Number" required>
                                            {poNumbers?.map((po) => (
                                                <MenuItem key={po.orderId} value={po.orderId}>
                                                    {po.poNumber}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.poNumber && <Typography color="error">{errors.poNumber.message}</Typography>}
                            </FormControl>
                        </Grid2>

                        <Grid2 size={4} sm={4}>
                            <FormControl fullWidth error={!!errors.poNumber} required>
                                {/* <InputLabel>Price per ton</InputLabel> */}
                                <Controller
                                    name="pricePerTon"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField label="Price per ton" type="number"> </TextField>

                                    )}
                                />
                                {errors.pricePerTon && <Typography color="error">{errors.pricePerTon.message}</Typography>}
                            </FormControl>
                        </Grid2>
                    </Grid2>
                    <div style={{ marginTop: "20px" }}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: "bold",
                                color: "#333",
                                p: 1,
                                mb: 2,
                                borderLeft: "4px solid #1976d2",
                                bgcolor: "#f0f0f0",
                                pl: 2,
                            }}
                        >
                            Trip Information
                        </Typography>
                    </div>
                    <Grid2 container spacing={2} pb={2}>
                        <Grid2 size={6} sm={6}>
                            <FormControl fullWidth error={!!errors.paymentTo}>
                                <FormLabel>Payment To</FormLabel>
                                <Controller
                                    name="paymentTo"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup {...field} row>
                                            <FormControlLabel value="aggregator" control={<Radio />} label="Aggregator" />
                                            <FormControlLabel value="owner" control={<Radio />} label="Owner" />
                                        </RadioGroup>
                                    )}
                                />
                                {errors.paymentTo && <Typography color="error">{errors.paymentTo.message}</Typography>}
                            </FormControl>
                        </Grid2>

                    </Grid2>
                    <Grid2 container spacing={2} pb={2}>
                        <Grid2 size={6} sm={6}>
                            <FormControl fullWidth error={!!errors.truckId}>
                                <FormLabel>Truck No.</FormLabel>
                                <Controller
                                    name="truckId"
                                    control={control}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            required
                                            classNamePrefix="mySelect"
                                            {...field}
                                            cacheOptions
                                            loadOptions={loadTruckOptions}
                                            defaultOptions

                                            onChange={(e) => {
                                                field.onChange(e);
                                                setTruckDetail(e);
                                            }}
                                        />
                                    )}
                                />
                                {errors.truckId && <Typography color="error">{errors.truckId.message}</Typography>}
                            </FormControl>
                        </Grid2>
                        <Grid2 size={6} sm={6} pt={2.7}>
                            <TextField
                                fullWidth
                                label="Driver Name"
                                value={truckDetail?.driver?.firstName || ""}
                                disabled
                            />
                        </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2}>
                        <Grid2 size={6} sm={6} >
                            <FormControl fullWidth error={!!errors.weight}>
                                <InputLabel id="level-label">Tds</InputLabel>
                                <Controller
                                    name="tdsId"
                                    id="tdsId"
                                    defaultValue={''}
                                    control={control}
                                    render={({ field }) => (
                                        <Select labelId="level-label" {...field} label="Tds">
                                            {tdsPercantages?.map((option) => {
                                                if (!option.value == 0) {
                                                    return (<MenuItem key={option.tdsPercentageId} value={option.tdsPercentageId}>
                                                        {option.value}
                                                    </MenuItem>)
                                                }
                                            })}
                                        </Select>
                                    )}
                                />
                                <FormHelperText error={true}>{errors.tdsId?.message}</FormHelperText>
                            </FormControl>
                        </Grid2>
                        <Grid2 size={6} sm={6} >
                            <FormControl fullWidth error={!!errors.gstId}>
                                <InputLabel id="level-label">Gst</InputLabel>
                                <Controller
                                    name="gstId"
                                    id="gstId"
                                    defaultValue={''}
                                    control={control}
                                    render={({ field }) => (
                                        <Select labelId="level-label" {...field} label="Tds">
                                            {gstPercantages?.map((option) => {
                                                if (!option.value == 0) {
                                                 return   <MenuItem key={option.gstPercentageId} value={option.gstPercentageId}>
                                                        {option.value}
                                                    </MenuItem>
                                                }
                                            })}
                                        </Select>
                                    )}
                                />
                                <FormHelperText error={true}>{errors.tdsId?.message}</FormHelperText>
                            </FormControl>

                        </Grid2>
                    </Grid2>
                    <Box display="flex" justifyContent="flex-end" mt={3}>
                        <Button type="submit" variant="contained" color="primary" disabled={!isDirty || !isValid}>
                            Submit
                        </Button>
                    </Box>
                </form>
            </Box>
        </Drawer>
    );
};

export default TripDrawer;
