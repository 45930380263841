import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";

const AddressDetails = ({
  onChange,
  initialValue: {
    address,
    landmark,
    locality,
    tehsil,
    district,
    state,
    pincode,
  },
}) => {
  return (
    <Box>
      <Text color={"#9d9b9b"} mb={3}>
        Address Details
      </Text>
      <Flex justify="space-between" w="100%" gap={3} mb={3}>
        <FormControl>
          <FormLabel color={"#191b1c"}>Address</FormLabel>
          <Input
            name="address"
            value={address || ""}
            type="text"
            onChange={onChange}
          />
        </FormControl>
        <FormControl w="60%">
          <FormLabel color={"#191b1c"}>Landmark</FormLabel>
          <Input
            name="landmark"
            value={landmark || ""}
            type="text"
            onChange={onChange}
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" w="100%" gap={3} mb={3}>
        <FormControl>
          <FormLabel color={"#191b1c"}>Village/town</FormLabel>
          <Input
            name="locality"
            value={locality || ""}
            type="text"
            onChange={onChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>Tehsil</FormLabel>
          <Input
            name="tehsil"
            value={tehsil || ""}
            type="text"
            onChange={onChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>District</FormLabel>
          <Input
            name="district"
            value={district || ""}
            type="text"
            onChange={onChange}
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" w="100%" gap={3} mb={3}>
        <FormControl w="50%">
          <FormLabel color={"#191b1c"}>State</FormLabel>
          <Input
            name="state"
            value={state || ""}
            type="text"
            onChange={onChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel color={"#191b1c"}>Pincode</FormLabel>
          <Input
            name="pincode"
            value={pincode}
            type="number"
            onChange={onChange}
          />
        </FormControl>
      </Flex>
    </Box>
  );
};

export default AddressDetails;
