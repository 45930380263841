import { useState } from "react";
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import zIndex from "@mui/material/styles/zIndex";

const useConfirmationDialog = (deleteItem) => {
  console.log(deleteItem)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isProcessing, setIsProcessing] = useState(false);
  const [itemId, setItemId] = useState(null);

  const handleConfirmAction = async () => {
    setIsProcessing(true);
    await deleteItem(itemId);
    setIsProcessing(false);
    onClose();
  };

  const openConfirmationDialog = (id) => {
    console.log(id)
    setItemId(id);
    onOpen();
  };

  const Dialog = (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={undefined}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Item
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete this item? This action cannot be
            undone.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} disabled={isProcessing}>
              Cancel
            </Button>
            <Button
              onClick={() => handleConfirmAction()}
              colorScheme="red"
              ml={3}
              isLoading={isProcessing}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );

  return {
    isOpen,
    onClose,
    isProcessing,
    openConfirmationDialog,
    Dialog,
  };
};

export default useConfirmationDialog;
