import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useYupValidationResolver } from "../../../hooks/use-validation-resolver";
import AsyncSelect from "react-select/async";
import * as Yup from "yup";
import { useAuth } from "../../../auth/AuthProvider";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { useGet } from '../../../hooks/api-call';
import { baseURL } from '../../../hooks/helper';


const DriverInfoModal = ({ onClose, open, initialValue, onSubmit }) => {
  console.log('entry')
  console.log(onSubmit)
  const { fetchData: fetchOrders } = useGet();
  const { token } = useAuth();
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const validationSchema =
    Yup.object().shape({
      // Grouping new driver fields under `newDriver`
      driverId: Yup.object()
        .nullable()
        .when("selectDriver", {
          is: "existing",
          then: (schema) => schema.required("Please select a driver"),
          otherwise: (schema) => schema.notRequired(),
        }),
      firstName: Yup.string()
        .when("selectDriver", {
          is: "new",
          then: (schema) => schema.required("First Name is required"),
          otherwise: (schema) => schema.notRequired(),
        }),

      contactNo: Yup.string()
        .when("selectDriver", {
          is: "new",
          then: (schema) =>
            schema
              .required("Contact Number is required")
              .matches(/^[0-9]{10}$/, "Must be a valid 10-digit phone number")
              .test("is-unique", "Contact number already exists", async (value) => {
                if (!value) return false; // Ensure value is present
                //if (value === initialContactNo) return true; // ✅ Skip validation if unchanged in edit form

                try {
                  const response = await fetch(
                    `${baseURL}/drivers/checkForDuplicates?contactNumber=${value}`,
                    { headers: headers }
                  );
                  const data = await response.json();
                  return !data; // Return true if available, false if already exists
                } catch (error) {
                  console.error("Error validating contact number:", error);
                  return false; // Assume invalid if API fails
                }
              }),
          otherwise: (schema) => schema.notRequired(),
        }),
    });
  const loadDriverOptions = async (inputValue) => {
    if (!inputValue) return [];
    if (inputValue.length < 3) return [];
    const response = await fetchOrders(`${baseURL}/driver/search?query=${inputValue}`);
    return response.map((driver) => ({

      value: driver.driverId,
      label: [
        driver.firstName,
        driver.middleName && driver.middleName.trim() !== "" ? driver.middleName : "",
        driver.lastName && driver.lastName.trim() !== "" ? driver.lastName : ""
      ].filter(Boolean).join(" ") + " - Mob No. " + driver.contactNo,
      //driver: truck.driver, // Store driver info
      ...driver,
    }));
  };
  useEffect(() => {
    reset(initialValue)
  }, [open]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    watch, clearErrors
  } = useForm({
    resolver: useYupValidationResolver(validationSchema),
    mode: "onBlur",  // Validate on blur
    reValidateMode: "onChange", // Revalidate when user types
    defaultValues: initialValue || {
      selectDriver: "existing",
      driverId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      contactNo: "",
    },
  });
  const selectedDriverType = watch("selectDriver");
  useEffect(() => {
    if (selectedDriverType === "existing") {
      reset({
        selectDriver: "existing",
        driverId: null, // Reset driver selection
        firstName: "",
        contactNo: "",
      });
      clearErrors(); // ✅ Clear errors when switching
    } else {
      reset({
        selectDriver: "new",
        driverId: null,
        firstName: "",
        contactNo: "",
      });
      clearErrors(); // ✅ Ensure no validation errors linger
    }
  }, [selectedDriverType, reset, clearErrors]);
 
  return (
    <>
      <Modal
        size="xl"
        isCentered
        blockScrollOnMount={false}
        isOpen={open}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Driver Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box pb={4}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Select Driver Type */}
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} pb={4}>
                  <FormControl isInvalid={errors.selectDriver}>
                    <FormLabel>Select Driver</FormLabel>
                    <Controller
                      name="selectDriver"
                      control={control}
                      defaultValue="existing"
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="existing">Existing Driver</Radio>
                            <Radio value="new">New Driver</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                    <FormErrorMessage>{errors.selectDriver?.message}</FormErrorMessage>
                  </FormControl>
                </SimpleGrid>

                {/* Existing Driver Selection */}
                {selectedDriverType === "existing" && (
                  <SimpleGrid>
                    <FormControl isInvalid={errors.driverId}>
                      <FormLabel>Driver</FormLabel>
                      <Controller
                        name="driverId"
                        control={control}
                        render={({ field }) => (
                          <AsyncSelect
                            placeholder="Enter Driver name or Mob no."
                            isClearable
                            classNamePrefix="mySelect"
                            {...field}
                            cacheOptions
                            loadOptions={loadDriverOptions}
                            defaultOptions
                            onChange={(e) => field.onChange(e)}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({ ...base, zIndex: 9999 })
                            }}
                          />
                        )}
                      />
                      <FormErrorMessage>{errors.driverId?.message}</FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>
                )}

                {/* New Driver Form */}
                {selectedDriverType === "new" && (
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} pb={4}>
                    <FormControl isInvalid={errors.firstName}>
                      <FormLabel>First Name</FormLabel>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                      <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl>
                      <FormLabel>Middle Name (Optional)</FormLabel>
                      <Controller
                        name="middleName"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </FormControl>

                    <FormControl isInvalid={errors.lastName}>
                      <FormLabel>Last Name</FormLabel>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                    </FormControl>

                    <FormControl isInvalid={errors.contactNo}>
                      <FormLabel>Mobile Number</FormLabel>
                      <Controller
                        name="contactNo"
                        control={control}
                        render={({ field }) => <Input {...field} />}
                      />
                      <FormErrorMessage>{errors.contactNo?.message}</FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>
                )}
                {/* Buttons: Submit and Close */}
                <HStack spacing={4} justify="flex-end" pt={4}>
                  <Button colorScheme="gray" onClick={onClose}>
                    Close
                  </Button>
                  <Button colorScheme="blue" type="submit" disabled={!isDirty || !isValid}>
                    Submit
                  </Button>
                </HStack>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>)
}

export default DriverInfoModal;