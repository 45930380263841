import React, { useEffect, useRef } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import AddressDetails from "./AddressDetails";
import PhoneNumberInput from "../../../atoms/PhoneNumberInput";
import NumberInput from "../../../atoms/NumberInput";

const DriverDetails = ({
  activeStep,
  handleChange,
  initialValue: {
    firstName,
    middleName,
    lastName,
    dob,
    fatherName,
    contactNo,
    martialStatus,
    spouseName,
    kids,
    marriageAnniversary,
    preferredLanguages,
    homeCity,
    ...rest
  },
}) => {
  const myRef = useRef(null);
  // Function to handle form changes
  const handleFormChange = (e) => {
    handleChange(e, "driver");
  };

  useEffect(()=>{
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  },[])

  return (
    // Render component only if activeStep is 3
    <Box ref={myRef} display={activeStep === 3 ? "block" : "none"}>
      {/* Personal Details section */}
      <Text color={"#9d9b9b"} mb={3}>
        Personal Details
      </Text>

      {/* Flex container for personal details */}
      <Flex justify="space-between" gap={3} mb={3}>
        {/* First name input */}
        <FormControl isRequired>
          <FormLabel color={"#191b1c"}>First name</FormLabel>
          <Input
            name="firstName"
            value={firstName || ""}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>

        {/* Middle name input */}
        <FormControl>
          <FormLabel color={"#191b1c"}>Middle name</FormLabel>
          <Input
            name="middleName"
            value={middleName || ""}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>

        {/* Last name input */}
        <FormControl>
          <FormLabel color={"#191b1c"}>Last name</FormLabel>
          <Input
            name="lastName"
            value={lastName || ""}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>
      </Flex>

      {/* Flex container for additional personal details */}
      <Flex justify="space-between" gap={3} mb={3}>
        {/* Date of birth input */}
        <FormControl >
          <FormLabel color={"#191b1c"}>Date of birth</FormLabel>
          <Input
            name="dob"
            value={dob}
            type="date"
            onChange={handleFormChange}
          />
        </FormControl>

        {/* Father's name input */}
        <FormControl>
          <FormLabel color={"#191b1c"}>Father's name</FormLabel>
          <Input
            name="fatherName"
            value={fatherName || ""}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>

        {/* Contact number input */}
        <FormControl isRequired>
          <FormLabel color={"#191b1c"}>Contact no.</FormLabel>
          <PhoneNumberInput
            name="contactNo"
            value={contactNo || ""}
            type="number"
            onChange={handleFormChange}
          />
        </FormControl>
      </Flex>

      {/* Flex container for marital status and related details */}
      <Flex justify="space-between" gap={3} mb={3}>
        {/* Marital status select */}
        <FormControl >
          <FormLabel color={"#191b1c"}>Marital status</FormLabel>
          <Select
            name="martialStatus"
            placeholder="Select Marital Status"
            onChange={handleFormChange}
            value={martialStatus || ""}
          >
            <option value="single">Single</option>
            <option value="married">Married</option>
            <option value="divorced">Divorced</option>
            <option value="widowed">Widowed</option>
          </Select>
        </FormControl>

        {/* Spouse name input */}
        <FormControl>
          <FormLabel color={"#191b1c"}>Spouse name</FormLabel>
          <Input
            name="spouseName"
            value={spouseName || ""}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>

        {/* Number of kids input */}
        <FormControl>
          <FormLabel color={"#191b1c"}>Number of kids</FormLabel>
          <NumberInput
            name="kids"
            value={kids || 0}
            type="number"
            onChange={handleFormChange}
          />
        </FormControl>
      </Flex>

      {/* Flex container for additional details */}
      <Flex justify="space-between" gap={3} mb={8}>
        {/* Marriage Anniversary input */}
        <FormControl>
          <FormLabel color={"#191b1c"}>Marriage Anniversary</FormLabel>
          <Input
            name="marriageAnniversary"
            type="date"
            onChange={handleFormChange}
            value={marriageAnniversary}
          />
        </FormControl>

        {/* Preferred language input */}
        <FormControl>
          <FormLabel color={"#191b1c"}>Preferred language</FormLabel>
          <Input
            name="preferredLanguages"
            type="text"
            onChange={handleFormChange}
            value={preferredLanguages || ""}
          />
        </FormControl>

        {/* Home city input */}
        <FormControl>
          <FormLabel color={"#191b1c"}>Home city</FormLabel>
          <Input
            name="homeCity"
            value={homeCity}
            type="text"
            onChange={handleFormChange}
          />
        </FormControl>
      </Flex>

      {/* Include AddressDetails component */}
      <AddressDetails onChange={handleFormChange} initialValue={rest} />
    </Box>
  );
};

export default DriverDetails;
