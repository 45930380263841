import { useEffect, useState } from "react";
import { Card, CardContent, Tabs, Tab, ThemeProvider, createTheme } from "@mui/material";
import TripTable from "./TripTable";
import { useAuth } from "../../../auth/AuthProvider";
import TripDrawer from "./TripDrawer";
import EditTripDrawer from "../../../admin-section/trips/drawer-contents/EditTripDrawer";
import { useQueryClient } from "@tanstack/react-query";
import { baseURL } from "../../../hooks/helper";
//import EditTripDrawer from "./EditTripDrawer";

export const theme = createTheme({
    palette: {
        mode: 'light',
        background: { default: '#fff' },
    },
});

const AdminSectionTrip = () => {
    const [tabs, setTabs] = useState([]);
    const [selectedTabId, setSelectedTabId] = useState(null);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [editabeTripId, setEditableTripId] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const handleAddTrip = async () => {
        console.log("emtry")
        setIsDrawerOpen(true)
    }
    const handleEditOpen = (trip) => {
        console.log("tripId", trip.original.tripId)
        setIsDrawerOpen(false);
        setIsEditDrawerOpen(true);
        setEditableTripId(trip.original.tripId);
    };
    const queryClient = useQueryClient(); 
    const handleEditTripClose = () => {
        setIsEditDrawerOpen(false);
        setEditableTripId(false);
        //fetchTripsData(0);
        fetchStages()
        queryClient.invalidateQueries(['trucks-list']);
    };
    const { token } = useAuth();

    useEffect(() => {
        fetchStages();
    }, []);

    const fetchStages = async () => {
        try {
            const response = await fetch(`${baseURL}/trips/stageWiseCount`, {
                headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
            });
            if (!response.ok) throw new Error("Failed to fetch stages");

            let data = await response.json();
            data.push({ id: 0, stageName: "All Trips", stageCount: data.reduce((acc, curr) => acc + curr.stageCount, 0) })
            const sortedData = [...data].sort((a, b) => a.id - b.id);
            //sortedData.push({ id: 0, stageName: "All Trips", stageCount: sortedData.reduce((acc, curr) => acc + curr.stageCount, 0) })
            setTabs(sortedData);
            setSelectedTabId(sortedData[0]);
        } catch (err) {
            console.error(err.message);
        }
    };
    const handleTabChange = (_, newIndex) => {
        const selectedTab = tabs[newIndex];
        console.log("Tab Clicked: ", selectedTab);

        setSelectedTabId(selectedTab.id); // Update state

        setTimeout(() => {
            console.log("After state update (timeout):", selectedTabId);
        }, 0);
    };
    const selectedIndex = tabs.findIndex((tab) => tab.id === selectedTabId);
    return (
        <>
            <ThemeProvider theme={theme}>
                <Card>
                    <CardContent>
                        <Tabs
                            value={selectedIndex >= 0 ? selectedIndex : 0}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                            sx={{
                                flexGrow: 1,
                                "& .MuiTab-root": {
                                    fontSize: "0.75rem",
                                    padding: "4px 8px",
                                    minWidth: "80px",
                                },
                                "& .Mui-selected": {
                                    fontWeight: "bold",
                                    backgroundColor: "#f5f5f5",
                                    borderRadius: "8px",
                                },
                            }}
                        >
                            {/* {<Tab
                            label={`${'All Trips'}`}
                            value={1}
                            onChange={handleTabChange}
                        />} */}
                            {tabs.map((tab, index) => (
                                <Tab
                                    label={`${tab.stageName} (${tab.stageCount})`}
                                    value={index}
                                    onChange={handleTabChange}
                                />
                            )
                            )}
                        </Tabs>
                        <TripTable tabs={tabs} selectedTabId={selectedTabId} handleAddTrip={handleAddTrip} handleEditOpen={handleEditOpen} />
                    </CardContent>
                </Card>
                {isDrawerOpen && (
                    <TripDrawer
                        setIsDrawerOpen={setIsDrawerOpen}
                        fetchStageWiseCount={tabs}
                    />
                )}    
            </ThemeProvider>
            {isEditDrawerOpen && <EditTripDrawer
                editabeTripId={editabeTripId}
                onClose={handleEditTripClose}
                isEditDrawerOpen={isEditDrawerOpen}
            />}

        </>
    );
};

export default AdminSectionTrip;
