import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Badge, Box, Button, Chip, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth } from '../../../auth/AuthProvider';
import withAdminAuthorization from '../../../hoc/ithAdminAuthorization';
import { FiCalendar, FiEdit, FiMoreVertical, FiTrash2, FiTrip } from 'react-icons/fi';
import useConfirmationDialog, { ConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { useDelete } from '../../../hooks/api-call';
import useCustomToast from "../../../hooks/use-toast";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TripDrawer from '../drawer-contents/TripDrawer';
import EditTripDrawer from '../drawer-contents/EditTripDrawer';
import { baseURL, convertIndianFormat } from '../../../hooks/helper';

export const TripChip = ({ truckNumber }) => {
  return (
    <Chip
      label={truckNumber}
      sx={{
        backgroundColor: "#FFD700", // Yellow background
        color: "#000", // Black text
        fontWeight: "bold",
        fontSize: "0.8rem",
        borderRadius: "8px", // Light curve
        //padding: "px 1px 2px 1px",
        border: "2px solid #000", // Black border for a plate-like feel
        minWidth: "70px",
        textAlign: "center",
      }}
    />
  );
};

const theme = createTheme({
  palette: {
    mode: 'light', // or 'dark'
    background: {
      default: '#fff', // example color
    },
  },
  typography: {
    // fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    // h1: {
    //   fontFamily: 'var(--chakra-fonts-heading)',
    // },
    // h2: {
    //   fontFamily: 'var(--chakra-fonts-heading)',
    // },
    // body1: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // body2: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // button: {
    //   fontFamily: 'var(--chakra-fonts-body)',
    // },
    // code: {
    //   fontFamily: 'var(--chakra-fonts-mono)',
    // },
  },
});

export const RowActions = ({ row, handleEditOpen, openConfirmationDialog }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', gap: '20px', width: '100%' }}>
      {/* Edit Button */}
      <Tooltip title="Edit">
        <IconButton onClick={() => handleEditOpen(row)}>
          <FiEdit size={12} color='blue' />
        </IconButton>
      </Tooltip>

      {/* Three Dots Menu */}
      <IconButton onClick={handleMenuOpen}>
        <FiMoreVertical size={14} />
      </IconButton>

      {/* Menu with Delete Button */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem 
          onClick={() => {
            openConfirmationDialog(row.original.tripId);
            handleMenuClose();
          }} 
          sx={{ color: 'red' }}
        >
          <FiTrash2 size={12} style={{ marginRight: 8 }} />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};
const TripTable = ({ handleEditOpen, handleAddTrip, selectedTabId, tabs }) => {

  const { token } = useAuth();
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 });
  const { showSuccessToast } = useCustomToast();

  const handleRemoveTrip = async (tripId) => {
    console.log("Trip ID:", tripId);
    try {
      await deleteData(null, `trips/${tripId}`);
      showSuccessToast("Success", `Trip deleted successfully`);
      refetch();
    } catch (e) {
      console.log(e)
      showSuccessToast("Failed", `Something went wrong.. Failed to delete`);
    }
  };

  const { openConfirmationDialog, ConfirmationDialog } = useConfirmationDialog(handleRemoveTrip);
  const {
    data: { content = [], totalElements } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['trucks-list', { columnFilters, globalFilter, pagination, sorting }],
    queryFn: async () => {
      const fetchURL = new URL(`${baseURL}/trips/searchv1`);
      fetchURL.searchParams.set('page', `${pagination.pageIndex}`);
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      let filters = columnFilters.filter(f => f.id !== "stageName");

      // Enforce tab-based filtering
      if (selectedTabId && selectedTabId != 0) {
        const selectedTab = tabs.find(tab => tab.id === selectedTabId);
        console.log(selectedTab)
        if (selectedTab) {
          filters.push({ id: "latestTripStage.stageName", value: selectedTab.stageName });
        }
      }
      fetchURL.searchParams.set('filters', JSON.stringify(filters));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      const response = await fetch(fetchURL.href, { headers: headers });
      return response.json();
    },
    placeholderData: keepPreviousData,
  });
  const { deleteData } = useDelete();
  useEffect(() => {
    console.log("Selected Tab ID Updated:", selectedTabId);
    refetch();
  }, [selectedTabId]);

  const columns = useMemo(() => [
    {
      accessorKey: 'ravanaNo',
      header: (
        <Tooltip title="Rawanna No.">
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <LocalShippingIcon size={14} />
            Rawanna No.
          </Typography>
        </Tooltip>
      ),
      muiFilterTextFieldProps: {
        placeholder: 'Rawanna No', // Custom placeholder
      },
    },
    {
      accessorKey: 'order.buyer.buyerName',
      header: 'Buyer Name',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        const truncatedValue = value.length > 25 ? `${value.substring(0, 25)}...` : value;
        return (
          <Tooltip title={value} arrow>
            <span>{truncatedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'truck.truckNumber',
      header: 'Truck No.',
      Cell: ({ cell }) => (
        <TripChip truckNumber={cell.getValue()} />
      )
    },
    {
      accessorKey: 'order.material.materialName',
      header: 'Material',
    },
    {
      accessorKey: 'tripQuantity',
      header: 'Qty.(Tons)',
      filterVariant: 'range-slider',
      filterFn: 'betweenInclusive', // Ensures values within the range are included
      muiFilterSliderProps: {
        marks: true,  // Enables tick marks for better UI
        max: 80,     // Set a realistic maximum based on your data
        min: 0,       // Set the minimum quantity
        step: 10,     // Adjust step size as per your data
        valueLabelFormat: (value) => `${value} Tons`, // Format the label
      },
      Cell: ({ cell }) => (
        <Chip label={Number(cell.getValue()).toFixed(2)} color="success" size='small' style={{ height: '20px' }} />
      ),
    },
    {
      accessorKey: 'driver.firstName',
      header: 'Driver Name',
    },
    {
      accessorKey: 'driver.contactNo',
      header: 'Driver Contact No.',
    },
    {
      accessorKey: 'latestTripStage.stageName',
      header: 'Trip Status',
      Cell: ({ cell }) => (
        <Chip label={cell.getValue()} color={cell.getValue() === 'Trip Created' ? "primary" : "secondary"} size="small" />
      )
    },
    {
      accessorKey: 'modifiedDate',
      muiFilterTextFieldProps: {
        placeholder: 'modified date', // Custom placeholder
      },
      header: (
        <Tooltip title="Last Updated Date">
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <FiCalendar size={14} />
            Last Updated
          </Typography>
        </Tooltip>
      ),
      Cell: ({ cell }) => convertIndianFormat(cell.getValue()),
       /** 🔹 Custom Filtering Logic */
    }
    // {
    //   accessorKey: 'status',
    //   header: 'Status',
    //   Cell: ({ cell }) => (
    //     <Chip label={cell.getValue()} color={cell.getValue() === 'Completed' ? "success" : "warning"} size="small" />
    //   ),
    //   filterVariant: 'checkbox',
    // },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: content,
    manualFiltering: true,
    enableColumnPinning: true,
    enableSorting: false,
    manualPagination: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    //manualSorting: true,
    desnity: 'comfortable',
    initialState: { density: 'compact', pagination: { pageIndex: 0, pageSize: 20 }, columnPinning: { left: ['truck.truckNumber', 'ravanaNo'], right: ['mrt-row-actions'], } },
    muiToolbarAlertBannerProps: isError ? { color: 'error', children: 'Error loading data' } : undefined,
    muiPaginationProps: {
      rowsPerPageOptions: [20, 25, 50, 100],
      showFirstButton: true,
      showLastButton: true,
      sx: {
        color: "primary", // Text color
        // backgroundColor: "blue", // Background color
        "&.Mui-selected": {
          backgroundColor: "red", // Active page color
          color: "white", // Active page text color
        }
      },
    },
    paginationDisplayMode: 'pages',
    enableEditing: true,
    //editDisplayMode: 'custom',
  renderRowActions: ({ row, table }) => (
       <RowActions  row={row} handleEditOpen={handleEditOpen} openConfirmationDialog={openConfirmationDialog}/>
     ),
    // renderRowActionMenuItems: () => [<MenuItem key="action">Action</MenuItem>],
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '3px', width: '100%', marginLeft: '10px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={refetch} >
            <RefreshIcon onClick={refetch} />
          </IconButton>
        </Tooltip>
        <Button size='medium' variant='contained' color='primary' onClick={handleAddTrip} >
          Add Trip
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            table.resetColumnFilters(); // Clear individual column filters
            table.setGlobalFilter('');  // Clear global filter
          }}
        >
          Clear Filters
        </Button>
      </Box>

    ),
    rowCount: totalElements ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,

    },
    muiTableBodyRowProps: ({ staticRowIndex }) => ({
      sx: {
        backgroundColor: staticRowIndex.index % 2 === 0 ? 'white' : 'grey.100',
      },
    })
  });

  return (<>{ConfirmationDialog} <MaterialReactTable table={table} /></>);
};


// function ({tabs,selectedTabId,handleAddTrip}) {
//   const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
//   // const handleEditTripClose = () => {
//   //   setIsEditDrawerOpen(false);
//   //   setEditableTripId(false);
//   // //  fetchTripsData(0);
//   // };

//   const ReactQueryDevtoolsProduction = lazy(() =>
//     import('@tanstack/react-query-devtools/build/modern/production.js').then(
//       (d) => ({
//         default: d.ReactQueryDevtools,
//       }),
//     ),
//   );
// //   useEffect(() => {
// //     console.log("Selected Tab ID Updated:", selectedTabId);
// // }
// // , [selectedTabId]);
//   return (
//         <div style={{ height: '100%', width: '100%', margin: '8px' }}>
//           <Example handleEditOpen={handleEditOpen}  handleAddTrip={handleAddTrip} tabs={tabs} selectedTabId={selectedTabId}/>
//         </div>

//   );
// }
export default withAdminAuthorization(TripTable)
