import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Link
} from "@chakra-ui/react";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { convertMillisecondsToDate } from "../../hooks/helper";

const IconBox = ({ quantity, label, active, onClick, isNotmarginRequired }) => {
  return (
    <Flex
      onClick={onClick}
      alignItems={"center"}
      p={2}
      bg={"#edf1fa"}
      border={active ? "1px solid #f8a9a3" : "1px solid transparent"}
      _hover={{ backgroundColor: "#cdd5e5", border: "1px solid #f8a9a3" }}
      borderRadius={5}
      cursor={"pointer"}
      width={"full"}
      marginRight={isNotmarginRequired ? "" : 3}
    >
      <Box p={2} bg="white" borderRadius={4}>
        <BsFileEarmarkMedical fontSize={"30px"} color="#f8a9a3" />
      </Box>
      <Box ml={2} display={"flex"} flexDir={"column"} alignItems={"center"}>
        <Text
          lineHeight={"24px"}
          fontSize={"30px"}
          fontWeight={"600"}
          color="#2c2d5b"
        >
          {quantity}
        </Text>
        <Text color={active ? "#2c2d5b" : "#acb5c2"}>{label}</Text>
      </Box>
    </Flex>
  );
};

const OrderListing = ({
  isLoading,
  orders,
  handleRemoveOrder,
  activePage,
  setActivePage,
  totalPages,
  fetchTrucksData,
  orderStageCount,
}) => {
  const [activeState, setActveState] = useState(null);

  const handleNextPage = () => {
    if (totalPages > activePage) {
      const page = activePage + 1;
      setActivePage(page);
      fetchTrucksData(page);
    }
  };

  const handlePrevPage = () => {
    if (activePage > 1) {
      const page = activePage - 1;
      setActivePage(page);
      fetchTrucksData(page);
    }
  };

  return (
    <>
      <Box
        borderRadius={5}
        boxShadow={"md"}
        my={5}
        p={5}
        display={"flex"}
        justifyContent={"space-between"}
        bg={"white"}
      >
        {/* <Flex
          onClick={handleCreateNewOrder}
          alignItems={"center"}
          p={5}
          bg={"#edf1fa"}
          _hover={{ backgroundColor: "#cdd5e5" }}
          borderRadius={5}
          cursor={"pointer"}
        >
          <BsFilePlusFill fontSize={"30px"} color="#3436a8" />
          <Text fontWeight={"600"} color="#2c2d5b">
            Create New Order
          </Text>
        </Flex> */}
        <IconBox
          onClick={() => setActveState(0)}
          quantity={orderStageCount?.reduce(
            (acc, curr) => acc + curr.totalWeight,
            0
          )}
          label="Total qty"
          active={activeState === 0}
        />
        <IconBox
          onClick={() => setActveState(1)}
          quantity={orderStageCount
            ?.filter((f) => [1, 2].includes(f.id))
            .reduce((acc, curr) => acc + curr.totalWeight, 0)}
          label="Trip Initiated"
          active={activeState === 2}
        />
        <IconBox
          onClick={() => setActveState(2)}
          quantity={orderStageCount
            ?.filter((f) => [3, 4].includes(f.id))
            .reduce((acc, curr) => acc + curr.totalWeight, 0)}
          label="Parking Seller"
          active={activeState === 1}
        />
        <IconBox
          onClick={() => setActveState(3)}
          quantity={orderStageCount
            ?.filter((f) => [5, 6].includes(f.id))
            .reduce((acc, curr) => acc + curr.totalWeight, 0)}
          label="In Transit"
          active={activeState === 2}
        />
        <IconBox
          onClick={() => setActveState(4)}
          quantity={orderStageCount
            ?.filter((f) => [7].includes(f.id))
            .reduce((acc, curr) => acc + curr.totalWeight, 0)}
          label="Parking Buyer"
          active={activeState === 2}
        />
        {/* <IconBox
          onClick={() => setActveState(5)}
          quantity={orderStageCount
            ?.filter((f) => [7].includes(f.id))
            .reduce((acc, curr) => acc + curr.totalWeight, 0)}
          label="Remaning qty"
          active={activeState === 3}
        /> */}
        <IconBox
          onClick={() => setActveState(6)}
          quantity={orderStageCount
            ?.filter((f) => [8, 9].includes(f.id))
            .reduce((acc, curr) => acc + curr.totalWeight, 0)}
          label="Delivred qty"
          active={activeState === 4}
          isNotmarginRequired={true}
        />
      </Box>
      <TableContainer
        w="100%"
        borderRadius={"md"}
        border="1px"
        borderColor="gray.200"
        bg="whiteAlpha.600"
        maxHeight={"60vh"}
        overflowY="scroll"
      >
        {isLoading ? (
          <Box
            h={"60vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Spinner size="xl" />
          </Box>
        ) : (
          <Table size={"sm"}>
            <Thead bg="blackAlpha.50">
              <Tr>
                <Th>PO No</Th>
                <Th>Po Date</Th>
                <Th>Est Delivery Date</Th>
                <Th>Buyer</Th>
                <Th>Seller</Th>
                <Th>Mineral Name</Th>
                <Th>Ordered Qty</Th>
                <Th>Qty Delivered</Th>
                <Th>Trip Alloted</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orders?.map((order) => (
                <Tr key={order?.orderId}>
                  <Td>
                    <Link href={`/trip?selectedOrder=${order?.orderId}`}>
                      {order?.poNumber}
                    </Link>
                  </Td>
                  <Td>{convertMillisecondsToDate(order?.poDate)}</Td>
                  <Td>
                    {" "}
                    {`${convertMillisecondsToDate(
                      order?.expectedDeliveryDate
                    )}`}{" "}
                  </Td>

                  <Td>{order?.buyer?.buyerName}</Td>
                  <Td>{order?.seller?.mineName}</Td>
                  <Td>{order?.material?.materialName}</Td>
                  <Td>{order?.totalQuantity}</Td>
                  <Td>{order?.totalDeliveredQuantity}</Td>
                  <Td>{order?.tripCount}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
      <Box style={{ textAlign: "right" }} w="100%" px="5" my="3">
        <Button
          disabled={activePage < 2}
          onClick={handlePrevPage}
          width={"150px"}
        >
          Previous
        </Button>
        <span style={{ margin: "0 0.5rem" }}>
          Page {activePage} of {totalPages}
        </span>
        <Button
          disabled={totalPages === activePage}
          onClick={handleNextPage}
          width={"150px"}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default OrderListing;
